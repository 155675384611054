import * as config from "../config/dev.config";

const loginUrl = () => {
  return `${config.baseUrl}login`;
};

const logoutUrl = () => {
  return `${config.baseUrl}customerLogout`;
};

const getProfileUrl = () => {
  return `${config.baseUrl}accounts`;
};

const changePassword = () => {
  return `${config.baseUrl}change-password`;
};

const uploadDocument = () => {
  return `${config.baseUrl}uploadDoc`;
};

const getRejectApproveDocStatus = () => {
  return `${config.baseUrl}allDocs`;
};

const getUploadedDocs = () => {
  return `${config.baseUrl}uploadedDocs`;
};

const getDownloadedDocs = () => {
  return `${config.baseUrl}downloadedDocs`;
};

const getSharedDocs = () => {
  return `${config.baseUrl}sharedDocs`;
};

const getRecentActivity = () => {
  return `${config.baseUrl}recentActivities`;
};

const getDashboard = () => {
  return `${config.baseUrl}dashboard`;
};

const changeProfilePic = () => {
  return `${config.baseUrl}updateAvatar`;
};

const updateProfileData = () => {
  return `${config.baseUrl}updateProfile`;
};

const downloadDoc = () => {
  return `${config.baseUrl}downloadDocs`;
};

const shareDoc = () => {
  return `${config.baseUrl}shareDoc`;
};

const getPageContent = () => {
  return `${config.baseUrl}getPageContent`;
};

const getHomePageContent = () => {
  return `${config.baseUrl}getHomePage`;
};

const sendMessage = () => {
  return `${config.baseUrl}sendEnquiry`;
};

const forgotPassword = () => {
  return `${config.baseUrl}forgot-password`;
};


const resetPassowrd = () => {
  return `${config.baseUrl}reset-password`;
};



export {
  loginUrl,
  logoutUrl,
  getProfileUrl,
  changePassword,
  uploadDocument,
  getUploadedDocs,
  changeProfilePic,
  updateProfileData,
  getDownloadedDocs,
  getSharedDocs,
  getRecentActivity,
  getRejectApproveDocStatus,
  getDashboard,
  downloadDoc,
  shareDoc,
  getPageContent,
  sendMessage,
  forgotPassword,
  resetPassowrd,
  getHomePageContent,
};
