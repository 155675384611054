import Content from "components/Content";


import {
  Home,
  Login,
  Dashboard,
  UploadedDocs,
  DownloadHistory,
  SharedDocs,
  DownloadedDocs,
  History,
  RAU,
  UploadedDocStatus,
  PageNotFound,
  EditProfile,
  ContactUs,
  ResetPassword,
  DocsManagement,
  
} from "pages";

const routes = {
  home: {
    route: "/",
    component: Home,
    text: "Home",
    meta: {
      canGuest: true,
      canAuthUser: true,
    },
  },
  whatWeDo: {
    route: "/what-we-do",
    component: Content,
    text: "What we do",
    meta: {
      canGuest: true,
      canAuthUser: true,
    },
  },
  solutions: {
    route: "/solutions",
    component: Content,
    text: "Solutions",
    meta: {
      canGuest: true,
      canAuthUser: true,
    },
  },


  stropthreat: {
    route: "/we-stop-threat",
    component: Content,
    text: "We Stop Threats",
    meta: {
      canGuest: true,
      canAuthUser: true,
    },
  },

  vendors: {
    route: "/our-vendors",
    component: Content,
    text: "Our Vendors",
    meta: {
      canGuest: true,
      canAuthUser: true,
    },
  },

   homeendpointxdr: {
    route: "/we-protect-your-company",
    component: Content,
    text: "Endpoints And XDR",
    meta: {
      canGuest: true,
      canAuthUser: true,
    },
  },

  cloudsecurity: {
    route: "/exceptional-cybersecurity",
    component: Content,
    text: "Cloud Security",
    meta: {
      canGuest: true,
      canAuthUser: true,
    },
  },

  threatintelligence: {
    route: "/we-protect-your-company",
    component: Content,
    text: "We Protect Your Company",
    meta: {
      canGuest: true,
      canAuthUser: true,
    },
  },

  forresterwave: {
    route: "/people-over-profits",
    component: Content,
    text: "Forrester Wave",
    meta: {
      canGuest: true,
      canAuthUser: true,
    },
  },

  resources: {
    route: "/resources",
    component: Content,
    text: "Resources",
    meta: {
      canGuest: true,
      canAuthUser: true,
    },
  },
  partners: {
    route: "/partners",
    component: Content,
    text: "Partners",
    meta: {
      canGuest: true,
      canAuthUser: true,
    },
  },
  company: {
    route: "/company",
    component: Content,
    text: "Company",
    meta: {
      canGuest: true,
      canAuthUser: true,
    },
  },

  services: {
    route: "/services",
    component: Content,
    text: "Services",
    meta: {
      canGuest: true,
      canAuthUser: true,
    },
  },

  faq: {
    route: "/faq",
    component: Content,
    text: "FAQ",
    meta: {
      canGuest: true,
      canAuthUser: true,
    },
  },

  contact: {
    route: "/contact",
    component: ContactUs,
    text: "Contact",
    meta: {
      canGuest: true,
      canAuthUser: true,
    },
  },

  about: {
    route: "/about",
    component: Content,
    text: "About Us",
    meta: {
      canGuest: true,
      canAuthUser: true,
    },
  },

   sitemap: {
    route: "/sitemap",
    component: Content,
    text: "Sitemap",
    meta: {
      canGuest: true,
      canAuthUser: true,
    },
  },


  login: {
    route: "/login",
    component: Login,
    text: "Login",
    meta: {
      canGuest: true,
      canAuthUser: false,
    },
  },

  dashboard: {
    route: "/dashboard",
    component: Dashboard,
    text: "Dashboard",
    meta: {
      canGuest: false,
      canAuthUser: true,
    },
  },
  uploadedDocs: {
    route: "/uploaded-docs",
    component: UploadedDocs,
    text: "Uploaded docs",
    meta: {
      canGuest: false,
      canAuthUser: true,
    },
  },
  downloadHistory: {
    route: "/download-history",
    component: DownloadHistory,
    text: "Download History",
    meta: {
      canGuest: false,
      canAuthUser: true,
    },
  },
  sharedDocs: {
    route: "/shared-docs",
    component: SharedDocs,
    text: "Shared docs",
    meta: {
      canGuest: false,
      canAuthUser: true,
    },
  },
  downloadedDocs: {
    route: "/downloaded-docs",
    component: DownloadedDocs,
    text: "Downloaded docs",
    meta: {
      canGuest: false,
      canAuthUser: true,
    },
  },
  history: {
    route: "/history",
    component: History,
    text: "History",
    meta: {
      canGuest: false,
      canAuthUser: true,
    },
  },
  rau: {
    route: "/recent-activities",
    component: RAU,
    text: "Recent Activites with Uploads",
    meta: {
      canGuest: false,
      canAuthUser: true,
    },
  },

  docstatus: {
    route: "/uploaded-doc-status",
    component: UploadedDocStatus,
    text: "Uploaded Documents Status",
    meta: {
      canGuest: false,
      canAuthUser: true,
    },
  },


  editProfile: {
    route: "/edit-profile",
    component: EditProfile,
    text: "Profile",
    meta: {
      canGuest: false,
      canAuthUser: true,
    },
  },
  docsManagement: {
    route: "/docs-management",
    component: DocsManagement,
    text: "Document Management",
    meta: {
      canGuest: false,
      canAuthUser: true,
    },
  },

  pageNotFound: {
    route: "/not-found",
    component: PageNotFound,
    text: "Not Found",
    meta: {
      canGuest: true,
      canAuthUser: true,
    },
  }, 

  resetPassword: {
    route: "/reset-password/:token",
    component: ResetPassword,
    text: "Reset Password",
    meta: {
      canGuest: true,
      canAuthUser: true,
    },
  },


};

export default routes;
