import PropTypes from "prop-types";
import {
  pdfIcon,
  zipIcon,
  folderIcon,
  textIcon,
  rarIcon,
  copyIcon,
  shareIcon,
  downloadIcon,
  approve,
  reject,
  pending,
} from "components/Images";
import { useEffect, useState } from "react";
import { Datetime } from "components";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { copyToClipboard, downloadDoc, shareDoc } from "actions/common";
import { Pagination } from "antd";
import "../assets/scss/pagination.scss";

const getIcon = (type) => {
  switch (type) {
    case "pdf":
      return pdfIcon;
    default:
      return folderIcon;
  }
};

const Table = (props) => {
  const {
    tableHeaderData,
    copyEnabled,
    shareEnabled,
    downloadEnabled,
    fileStatus,
    ActionEnabled,
    TotalEnabled,
    status,
  } = props;
  const [tableData, setTableData] = useState([...props.tableData]);
  const [currentData, setCurrentData] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [currentPage, setCurrentPage] = useState(1);
  // const [totalPages, setTotlaPages] = useState(
  //   Math.ceil(props.tableData.length / 5)
  // );
  const getStatus = ["approve", "reject", "pending"];

  const dispatch = useDispatch();

  const setCurrentPageData = (data, page, rowsPerPage) => {
    return data.slice((page - 1) * rowsPerPage, page * rowsPerPage);
  };

  useEffect(() => {
    setTableData([...props.tableData]);
    setCurrentData([
      ...setCurrentPageData([...props.tableData], 1, rowsPerPage),
    ]);
  }, [props.tableData]);

  const onChangePage = (page, pageSize) => {
    setCurrentPage(page);
    setCurrentData([...setCurrentPageData([...tableData], page, pageSize)]);
  };

  const onRowPerPageChange = (current, size) => {
    setRowsPerPage(size);
  };

  useEffect(() => {
    setCurrentData([
      ...setCurrentPageData([...props.tableData], currentPage, rowsPerPage),
    ]);
  }, [rowsPerPage]);

  return (
    <>
      <table className="table table-borderless mb-0">
        <thead className="bg-primary text-white border-0">
          <tr>
            {tableHeaderData &&
              tableHeaderData.length > 0 &&
              tableHeaderData.map((header) => {
                return <th>{header}</th>;
              })}
            {(copyEnabled ||
              shareEnabled ||
              downloadEnabled ||
              fileStatus ||
              ActionEnabled ||
              TotalEnabled ||
              status) && <th>&nbsp;</th>}
          </tr>
        </thead>
        <tbody className="small align-middle">
          {currentData &&
            currentData.length > 0 &&
            currentData.map((data) => {
              if (data.name && data.file_type) {
                const namefile = data.name ? data.name.substring(0, 10) : "";
                return (
                  <tr>
                    <td>
                      <img
                        className="me-2"
                        src={getIcon(data.file_type)}
                        alt="text_icon"
                      />
                      &nbsp;
                      <span alt={data.name} title={data.name}>
                        {namefile}
                      </span>
                    </td>
                    <td>
                      <Datetime
                        date={new Date(data.created_at).toLocaleDateString()}
                        time={new Date(data.created_at).toLocaleTimeString()}
                      />
                    </td>
                    {(copyEnabled ||
                      shareEnabled ||
                      downloadEnabled ||
                      fileStatus ||
                      ActionEnabled ||
                      TotalEnabled ||
                      status) && (
                      <td>
                        <ul className="list-inline mb-0">
                          {copyEnabled && data.status == 1 && (
                            <li className="list-inline-item">
                              <a href="javascript:void(0)" className="lead">
                                <img
                                  src={copyIcon}
                                  className="icon"
                                  alt="copy_icon"
                                  onClick={async () => {
                                    dispatch(
                                      shareDoc(data, async (err, response) => {
                                        if (response) {
                                          await copyToClipboard(
                                            response.file_url
                                          );
                                          toast.success(
                                            "File URL is copied to clipboard.",
                                            { position: "top-right" }
                                          );
                                        }
                                      })
                                    );
                                  }}
                                />
                              </a>
                            </li>
                          )}

                          {fileStatus && data.status == 0 && (
                            <li className="list-inline-item">
                              <img src={pending} width="30" title="Pending" />
                            </li>
                          )}

                          {fileStatus && data.status == 1 && (
                            <li className="list-inline-item">
                              <img src={approve} width="30" title="Approve" />
                            </li>
                          )}

                          {fileStatus && data.status == 2 && (
                            <li className="list-inline-item">
                              <img src={reject} width="30" title="Reject" />
                            </li>
                          )}

                          {ActionEnabled && (
                            <li className="list-inline-item">{data.action}</li>
                          )}

                          {shareEnabled && data.status == 1 && (
                            <li className="list-inline-item">
                              <a className="lead">
                                <img
                                  src={shareIcon}
                                  className="icon"
                                  alt="copy_icon"
                                  onClick={async () => {
                                    dispatch(
                                      shareDoc(data, async (err, response) => {
                                        if (response) {
                                          await copyToClipboard(
                                            response.file_url
                                          );
                                          toast.success(
                                            "File URL is copied to clipboard.",
                                            { position: "top-right" }
                                          );
                                        }
                                      })
                                    );
                                  }}
                                />
                              </a>
                            </li>
                          )}
                          {downloadEnabled && (
                            <li className="list-inline-item">
                              &nbsp;
                              <a className="lead">
                                <img
                                  src={downloadIcon}
                                  className="icon"
                                  alt="download_icon"
                                  onClick={async () => {
                                    dispatch(
                                      downloadDoc(
                                        data,
                                        async (err, response) => {
                                          if (response) {
                                            const link =
                                              document.createElement("a");
                                            link.href = response.file_url;
                                            link.target = "_blank";
                                            link.setAttribute(
                                              "download",
                                              `${data.name}`
                                            );
                                            document.body.appendChild(link);
                                            link.click();
                                            // fetch(response.file_url, {
                                            //   mode: "no-cors",
                                            // }).then((response) => {
                                            //   console.log(
                                            //     response,
                                            //     response.blob()
                                            //   );
                                            //   response.blob().then((blob) => {
                                            //     // Creating new object of PDF file
                                            //     console.log(
                                            //       blob,
                                            //       mime.getType(
                                            //         `${data.name}.${data.file_type}`
                                            //       ),
                                            //       mime.getType(`name.pdf`)
                                            //     );
                                            //     const fileURL =
                                            //       window.URL.createObjectURL(blob, {
                                            //         type: `${mime.getType(
                                            //           `${data.name}.${data.file_type}`
                                            //         )}`,
                                            //       });
                                            //     // Setting various property values
                                            //     let alink =
                                            //       document.createElement("a");
                                            //     alink.href = fileURL;
                                            //     alink.download = `${data.name}`;
                                            //     alink.click();
                                            //   });
                                            // });

                                            toast.success("File is download.", {
                                              position: "top-right",
                                            });
                                          }
                                        }
                                      )
                                    );
                                  }}
                                />
                              </a>
                            </li>
                          )}
                        </ul>
                      </td>
                    )}
                    {status && data.status === 2 && (
                      <td>
                        <ul class="list-inline mb-0">
                          <li class="list-inline-item">
                            <a href="" class="lead">
                              <img
                                src={removeIcon}
                                class="icon"
                                alt="rejected_icon"
                              />
                            </a>
                          </li>
                        </ul>
                      </td>
                    )}
                    {status && data.status === 1 && (
                      <td>
                        <ul class="list-inline mb-0">
                          <li class="list-inline-item">
                            <a href="" class="lead">
                              <img
                                src={approvedIcon}
                                class="icon"
                                alt="approved_icon"
                              />
                            </a>
                          </li>
                        </ul>
                      </td>
                    )}
                    {status && data.status === 0 && (
                      <td>
                        <ul class="list-inline mb-0">
                          <li class="list-inline-item">
                            <a href="" class="lead">
                              <img
                                src={pendingIcom}
                                class="icon"
                                alt="pending_icon"
                                style={{ maxHeight: "32px" }}
                              />
                            </a>
                          </li>
                        </ul>
                      </td>
                    )}
                    {TotalEnabled && <td>{data.total}</td>}
                  </tr>
                );
              }
            })}
        </tbody>
      </table>
      <div
        className="col-12 mt-5"
        style={{ display: "flex", justifyContent: "center" }}
      >
        <Pagination
          pageSize={rowsPerPage}
          responsive
          total={tableData.length}
          defaultCurrent={currentPage}
          defaultPageSize={5}
          pageSizeOptions={[5, 10, 20, 50, 100]}
          onChange={onChangePage}
          onShowSizeChange={onRowPerPageChange}
        />
      </div>
    </>
  );
};

Table.propTypes = {
  tableHeaderData: PropTypes.array.isRequired,
  tableData: PropTypes.array.isRequired,
  copyEnabled: PropTypes.bool,
  shareEnabled: PropTypes.bool,
  downloadEnabled: PropTypes.bool,
  status: PropTypes.bool,
};

Table.defaultProps = {
  tableHeaderData: [],
  tableData: [],
  copyEnabled: false,
  shareEnabled: false,
  downloadEnabled: false,
  status: false,
};

export default Table;
