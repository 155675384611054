import React from 'react';
import { NavLink } from 'react-router-dom';
import { Router } from 'router';
import {
	dashboardIcon,
	plus,
	minus,
	profileIcon,
	uploadedDocsIcon,
	downloadHistoryIcon,
	sharedDocsIcon,
	downloadedDocsIcon,
	rejectApproveDocsIcon,
	historyIcon,
	recentActivitiesIcon,
} from 'components/Images';
import { highlightIfActive } from 'classes';

const Sidemenu = () => {
	return (
		<aside className="card">
			
			{/*}
			<div className='dash-mobile-ver-left-section'>
                 <div className='dash-mobile-ver-left-section-left'>
				 <img className="icon" src={plus} alt="icon" />
				 </div>
				 <div className='dash-mobile-ver-left-section-right'>
				 <img className="icon" src={minus} alt="icon" />
				 </div>
			</div>
	{*/}
			<nav className="card-body"> 
				<ul className="nav flex-column">
					<li className={`nav-item ${highlightIfActive('dashboard')}`}>
						<NavLink to={Router.getRoute('dashboard')} className="nav-link" end>
							<img className="icon" src={dashboardIcon} alt="icon" />
							<span>{Router.getText('dashboard')}</span>
						</NavLink>
					</li>
					<li className={`nav-item ${highlightIfActive('editProfile')}`}>
						<NavLink to={Router.getRoute('editProfile')} className="nav-link" end>
							<img className="icon" src={rejectApproveDocsIcon} alt="icon" />
							<span>{Router.getText('editProfile')}</span>
						</NavLink>
					</li>
					<li className={`nav-item ${highlightIfActive('uploadedDocs')}`}>
						<NavLink to={Router.getRoute('uploadedDocs')} className="nav-link" end>
							<img className="icon" src={uploadedDocsIcon} alt="icon" />
							<span>{Router.getText('uploadedDocs')}</span>
						</NavLink>
					</li>
					{/*<li className={`nav-item ${highlightIfActive('downloadHistory')}`}>
						<NavLink to={Router.getRoute('downloadHistory')} className="nav-link" end>
							<img className="icon" src={downloadHistoryIcon} alt="icon" />
							<span>{Router.getText('downloadHistory')}</span>
						</NavLink>
					</li>
					*/}
					<li className={`nav-item ${highlightIfActive('sharedDocs')}`}>
						<NavLink to={Router.getRoute('sharedDocs')} className="nav-link" end>
							<img className="icon" src={sharedDocsIcon} alt="icon" />
							<span>{Router.getText('sharedDocs')}</span>
						</NavLink>
					</li>
					<li className={`nav-item ${highlightIfActive('downloadedDocs')}`}>
						<NavLink to={Router.getRoute('downloadedDocs')} className="nav-link" end>
							<img className="icon" src={downloadedDocsIcon} alt="icon" />
							<span>{Router.getText('downloadedDocs')}</span>
						</NavLink>
					</li>
					
					{/*<li className={`nav-item ${highlightIfActive('profile')}`}>
						<NavLink to={Router.getRoute('doc-status')} className="nav-link" end>
							<img className="icon" src={rejectApproveDocsIcon} alt="icon" />
							<span>Reject/Approve docs</span>
						</NavLink>
					</li>

					<li className={`nav-item ${highlightIfActive('history')}`}>
						<NavLink to={Router.getRoute('history')} className="nav-link" end>
							<img className="icon" src={historyIcon} alt="icon" />
							<span>{Router.getText('history')}</span>
						</NavLink>
					</li>
					*/}

					<li className={`nav-item ${highlightIfActive('docstatus')}`}>
						<NavLink to={Router.getRoute('docstatus')} className="nav-link" end>
							<img className="icon" src={rejectApproveDocsIcon} alt="icon" />
							<span>Reject/Approve docs</span>
						</NavLink>
					</li>

					
					<li className={`nav-item ${highlightIfActive('rau')}`}>
						<NavLink to={Router.getRoute('rau')} className="nav-link" end>
							<img className="icon" src={recentActivitiesIcon} alt="icon" />
							<span>{Router.getText('rau')}</span>
						</NavLink>
					</li>
					<li className={`nav-item ${highlightIfActive('docsManagement')}`}>
						<NavLink to={Router.getRoute('docsManagement')} className="nav-link" end>
							<img className="icon" src={uploadedDocsIcon} alt="icon" />
							<span>{Router.getText('docsManagement')}</span>
						</NavLink>
					</li>
				</ul>
			</nav>
		</aside>
	);
};

export default Sidemenu;
