import React, { useState, useCallback } from "react";
import { GuardRoute } from "hoc";
import { Sidemenu, Hero } from "layouts";
import { BannerVideo, TableCaption } from "components";
import {
  fileUploadIcon,
  arrowRight,
  folderIcon,
  removeIcon,
} from "components/Images";
import { useDropzone } from "react-dropzone";
import { endpoints, request } from "utils";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

const DocsManagement = () => {
  const [file, setFile] = useState(null);
  const [isUploading, setIsUploading] = useState(false);
  const token = useSelector((state) => state.auth?.authData?.token);
  const onDrop = useCallback((acceptedFiles) => {
    // Do something with the files
    console.log("Files", acceptedFiles);
    setFile(acceptedFiles[0]);
  }, []);
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: {
      "image/*": [".jpeg", ".png"],
      "text/html": [".html", ".htm"],
      "pdf": [".pdf", ".pdf"],
      "zip": [".zip", ".zip"],
      "doc": [".doc", ".doc"],
      "docx": [".docx", ".docx"],
      "zip": [".zip", ".zip"],
      "csv": [".csv", ".csv"],
    },
    maxFiles: 1,
  });
  const uploadDocument = async (e) => {
    e.preventDefault();
    setIsUploading(true);
    const url = endpoints.uploadDocument();
    const response = await request.uploadFile(url, file, token, true);
    if (response.status === 200) {
      setFile(null);
      toast.success("File Uploaded Successfully", { position: "top-right" });
    } else {
      toast.error("Error in uploading file. Try after sometime.");
    }
  };

  const removeFile = () => {
    setFile(null);
  };
  return (
    <>
      <Hero>Document Management</Hero>
      <section className="bannerSection py-13">
        <div className="container">
          <BannerVideo />
          <div className="row gx-9">
            <div className="col-auto">
              <Sidemenu />
            </div>
            <div className="col docs-management_frame">
              <TableCaption>Document Management</TableCaption>
              <p className="mb-10 text-white">
                Lorem ipsum dolor sit, amet consectetur adipisicing elit.
                Assumenda cumque voluptates maxime dolor hic impedit a est sint,
                nemo iste repellendus, velit laudantium corrupti sequi autem
                sit, blanditiis libero eos.
              </p>
              <div className="row gx-10 gy-6">
                {!file && (
                  <div className="col-7" {...getRootProps()}>
                    <div htmlFor="firstinput" className="file-label">
                      <div className="text-center">
                        <img
                          className="icon mb-3"
                          src={fileUploadIcon}
                          alt="upload"
                        />
                        <div className="d-block">
                          <span className="d-inline-block text-primary">
                            Choose a file
                          </span>
                          &nbsp;or&nbsp;
                          <span className="d-inline-block"> Drag it here </span>
                        </div>
                      </div>
                    </div>
                    <input
                      type="file"
                      className="form-control d-none"
                      id="firstinput"
                      {...getInputProps()}
                    />
                  </div>
                )}
                {file && (
                  <div className="col-12">
                    <img className="me-2" src={folderIcon} alt="pdf_icon" />
                    &nbsp;{file.name}
                    &nbsp; &nbsp;
                    <img
                      className="me-2"
                      src={removeIcon}
                      alt="pdf_icon"
                      style={{ height: "1.5em" }}
                      onClick={removeFile}
                    />
                  </div>
                )}
                <div className="col-12">
                  <button
                    className="btn btn-primary"
                    onClick={uploadDocument}
                    disabled={isUploading}
                  >
                    {isUploading ? "Uploading" : "Upload"}
                    <img className="icon ms-1" src={arrowRight} alt="arrow" />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default GuardRoute(DocsManagement);
