import React from 'react';
import bannerVideo from 'assets/videos/banner-video.mp4';

const BannerVideo = ({ className }) => {
	return (
		<div className="banner-video">
			<video loop autoPlay muted className={className}>
				<source src={bannerVideo} type="video/mp4" />
			</video>
		</div>
	);
};

export default BannerVideo;
