import React, { useEffect, useState } from "react";
import { GuardRoute } from "hoc";
import { Sidemenu, Hero } from "layouts";
import { Datetime, BannerVideo, TableCaption, Table } from "components";
import {
  pdfIcon,
  zipIcon,
  folderIcon,
  textIcon,
  rarIcon,
  copyIcon,
  downloadIcon,
  shareIcon,
} from "components/Images";
import { useDispatch } from "react-redux";
import { getUploadedDocs } from "actions/common";

const tableHeaderData = ["File Name", "Date/Time"];

const History = () => {
  const [uploadedDocs, setUploadedDocs] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      getUploadedDocs((err, response) => {
        console.log(response);
        response?.documents &&
          response?.documents.length > 0 &&
          setUploadedDocs([...response?.documents]);
      })
    );
  }, []);
  return (
    <>
      <Hero>History</Hero>
      <section className="bannerSection py-13">
        <div className="container">
          <BannerVideo />
          <div className="row gx-9">
            <div className="col-auto">
              <Sidemenu />
            </div>
            <div className="col">
              <TableCaption>History</TableCaption>
              {uploadedDocs && uploadedDocs.length > 0 && (
                <Table
                  tableHeaderData={tableHeaderData}
                  tableData={uploadedDocs}
                  copyEnabled={true}
                  shareEnabled={true}
                  downloadEnabled={true}
                />
              )}
              {/* <table className="table table-borderless mb-0">
                <thead className="bg-primary text-white border-0">
                  <tr>
                    <th>File Name</th>
                    <th>Date/Time</th>
                    <th>&nbsp;</th>
                  </tr>
                </thead>
                <tbody className="small align-middle">
                  <tr>
                    <td>
                      <img className="me-2" src={pdfIcon} alt="pdf_icon" />
                      &nbsp;Chat Application
                    </td>
                    <td>
                      <Datetime date="18 March 2023" time="9:20AM" />
                    </td>
                    <td>
                      <ul className="list-inline mb-0">
                        <li className="list-inline-item">
                          <a href="" className="lead">
                            <img
                              src={copyIcon}
                              className="icon"
                              alt="copy_icon"
                            />
                          </a>
                        </li>
                        <li className="list-inline-item">
                          &nbsp;
                          <a href="" className="lead">
                            <img
                              src={downloadIcon}
                              className="icon"
                              alt="download_icon"
                            />
                          </a>
                        </li>
                        <li className="list-inline-item">
                          &nbsp;
                          <a href="" className="lead">
                            <img
                              src={shareIcon}
                              className="icon"
                              alt="share-alt_icon"
                            />
                          </a>
                        </li>
                      </ul>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <img className="me-2" src={zipIcon} alt="zip_icon" />
                      &nbsp;Chat Application
                    </td>
                    <td>
                      <Datetime date="18 March 2023" time="9:20AM" />
                    </td>
                    <td>
                      <ul className="list-inline mb-0">
                        <li className="list-inline-item">
                          <a href="" className="lead">
                            <img
                              src={copyIcon}
                              className="icon"
                              alt="copy_icon"
                            />
                          </a>
                        </li>
                        <li className="list-inline-item">
                          &nbsp;
                          <a href="" className="lead">
                            <img
                              src={downloadIcon}
                              className="icon"
                              alt="download_icon"
                            />
                          </a>
                        </li>
                        <li className="list-inline-item">
                          &nbsp;
                          <a href="" className="lead">
                            <img
                              src={shareIcon}
                              className="icon"
                              alt="share-alt_icon"
                            />
                          </a>
                        </li>
                      </ul>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <img
                        className="me-2"
                        src={folderIcon}
                        alt="folder_icon"
                      />
                      &nbsp;Chat Application
                    </td>
                    <td>
                      <Datetime date="18 March 2023" time="9:20AM" />
                    </td>
                    <td>
                      <ul className="list-inline mb-0">
                        <li className="list-inline-item">
                          <a href="" className="lead">
                            <img
                              src={copyIcon}
                              className="icon"
                              alt="copy_icon"
                            />
                          </a>
                        </li>
                        <li className="list-inline-item">
                          &nbsp;
                          <a href="" className="lead">
                            <img
                              src={downloadIcon}
                              className="icon"
                              alt="download_icon"
                            />
                          </a>
                        </li>
                        <li className="list-inline-item">
                          &nbsp;
                          <a href="" className="lead">
                            <img
                              src={shareIcon}
                              className="icon"
                              alt="share-alt_icon"
                            />
                          </a>
                        </li>
                      </ul>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <img className="me-2" src={textIcon} alt="text_icon" />
                      &nbsp;Chat Application
                    </td>
                    <td>
                      <Datetime date="18 March 2023" time="9:20AM" />
                    </td>
                    <td>
                      <ul className="list-inline mb-0">
                        <li className="list-inline-item">
                          <a href="" className="lead">
                            <img
                              src={copyIcon}
                              className="icon"
                              alt="copy_icon"
                            />
                          </a>
                        </li>
                        <li className="list-inline-item">
                          &nbsp;
                          <a href="" className="lead">
                            <img
                              src={downloadIcon}
                              className="icon"
                              alt="download_icon"
                            />
                          </a>
                        </li>
                        <li className="list-inline-item">
                          &nbsp;
                          <a href="" className="lead">
                            <img
                              src={shareIcon}
                              className="icon"
                              alt="share-alt_icon"
                            />
                          </a>
                        </li>
                      </ul>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <img className="me-2" src={rarIcon} alt="rar_icon" />
                      &nbsp;Chat Application
                    </td>
                    <td>
                      <Datetime date="18 March 2023" time="9:20AM" />
                    </td>
                    <td>
                      <ul className="list-inline mb-0">
                        <li className="list-inline-item">
                          <a href="" className="lead">
                            <img
                              src={copyIcon}
                              className="icon"
                              alt="copy_icon"
                            />
                          </a>
                        </li>
                        <li className="list-inline-item">
                          &nbsp;
                          <a href="" className="lead">
                            <img
                              src={downloadIcon}
                              className="icon"
                              alt="download_icon"
                            />
                          </a>
                        </li>
                        <li className="list-inline-item">
                          &nbsp;
                          <a href="" className="lead">
                            <img
                              src={shareIcon}
                              className="icon"
                              alt="share-alt_icon"
                            />
                          </a>
                        </li>
                      </ul>
                    </td>
                  </tr>
                </tbody>
              </table> */}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default GuardRoute(History);
