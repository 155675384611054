import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Router } from 'router';
import { useLocation, Navigate } from 'react-router-dom';
import { LOGIN_USER_SUCCESS } from 'store/actions/types';
//import { useLocation  , useNavigate, useParams, useMatch , matchRoutes } from 'react-router-dom';

const GuardRoute = (WrappedComponent) => {

	return (props) => {
		const routeMeta = Router.getCurrentMeta;
		const { isAuth } = useSelector(({ auth }) => auth);
		const location = useLocation();
		const localStorageAuth = JSON.parse(localStorage.getItem('authData'))
		const dispatch = useDispatch();

		useEffect(()=>{
			if(!isAuth && localStorageAuth?.user){
				const update = {
					user: {...localStorageAuth?.user},
					authData: {...localStorageAuth.authData},
					isAuth: true
				}
				dispatch({ type: LOGIN_USER_SUCCESS, payload:update });
			}
		},[props])

		if (isAuth || localStorageAuth?.user) {
			//User is logged in
			if ('canAuthUser' in routeMeta && !routeMeta.canAuthUser) {
				//Page is not accessible by autheticated users. Redirect.
				console.log('Page is not accessible by autheticated users. Redirect.');
				return <Navigate to={Router.getRoute('dashboard')} state={{ from: location }} replace />;
			} else {
				//Page is accessible by autheticated users. Continue.
				console.log('Page is accessible by autheticated users. Continue.');
				return <WrappedComponent {...props} />;
			}
		} else {
			//User is not logged in
			if ('canGuest' in routeMeta && !routeMeta.canGuest) {
				//Page is not accessible by guests. Redirect.
				console.log('Page is not accessible by guests. Redirect.');
				return <Navigate to={Router.getRoute('home')} state={{ from: location }} replace />;
			} else {
				//Page is accessible by guests. Continue.
				console.log('Page is accessible by guests. Continue.');
				return <WrappedComponent {...props} />;
			}
		}
	};
};

export default GuardRoute;
