import React, { useEffect, useState } from "react";
import { GuardRoute } from "hoc";
import { Sidemenu, Hero } from "layouts";
import { Datetime, BannerVideo, TableCaption, Table } from "components";
import {
  pdfIcon,
  zipIcon,
  folderIcon,
  textIcon,
  rarIcon,
} from "components/Images";
import { getDashboard } from "actions/common";
import { useDispatch } from "react-redux";

const tableHeaderData = ["File Name", "Date and Time", "Action","Total"];

const Dashboard = () => {
  const [dashboardData, setDashboardData] = useState({});
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      getDashboard((err, response) => {
        console.log(response);
        setDashboardData({ ...response });
      })
    );
  }, []);

  return (
    <>
      <Hero>Dashboard</Hero>
      <section className="bannerSection py-13">
        <div className="container">
          <BannerVideo />

          <div className="row gx-9">
            <div className="col-auto">
              <Sidemenu />
            </div>
            <div className="col">
              <div className="row g-13 justify-content-lg-around text-center mx-0 mb-12">
                <div className="col-4">
                  <span className="stats mb-4 ratio ratio-1x1 border border-2 rounded-circle display-2 fw-normal text-lime border-lime">
                    {dashboardData?.uploadedDocs}
                  </span>
                  <h3 className="text-white mt-4 fw-normal">Reports</h3>
                  <p className="small mb-0">Total file uploads</p>
                </div>
                <div className="col-4">
                  <span className="stats mb-4 ratio ratio-1x1 border border-2 rounded-circle display-2 fw-normal text-pink border-pink">
                    {dashboardData?.downloadedDocs}
                  </span>
                  <h3 className="text-white mt-4 fw-normal">Download</h3>
                  <p className="small mb-0">Total download files</p>
                </div>
                <div className="col-4">
                  <span className="stats mb-4 ratio ratio-1x1 border border-2 rounded-circle display-2 fw-normal text-warning border-warning">
                    {dashboardData?.sharedDocs}
                  </span>
                  <h3 className="text-white mt-4 fw-normal">Shared</h3>
                  <p className="small mb-0">Total Shared docs</p>
                </div>
              </div>
              <TableCaption>Recent Activities</TableCaption>
              <div className="dashboard-table-frame">
              {dashboardData?.recentActivities &&
                dashboardData?.recentActivities.length > 0 && (
                  <Table
                    tableHeaderData={tableHeaderData}
                    tableData={dashboardData?.recentActivities}
                    ActionEnabled={true}
                    TotalEnabled={true}
                    
                  />
                )}
            </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default GuardRoute(Dashboard);
