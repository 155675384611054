import { getPageContent } from "actions/common";
import { GuardRoute } from "hoc";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";

import { Hero } from "layouts";
import {  BannerVideo } from "components";

const Content = (props) => {
  const { hash, pathname, search } = useLocation();
  const dispatch = useDispatch();
  const [contentLoaded, setContentLoaded] = useState(false);
  const [pageContent, setPageContent] = useState(null);

  useEffect(() => {
    dispatch(
      getPageContent(pathname.split("/")[1], (err, response) => {
        if (response) {
          console.log(response);
          setPageContent({ ...response.content });
          setContentLoaded(true);
        }
      })
    );
  }, [pathname]);

  if (contentLoaded) {
    return (
      <>
      <section>
        <Helmet>
          <meta charSet="utf-8" />
          {pageContent?.meta_title && <title>{pageContent?.meta_title}</title>}
        </Helmet>       
      </section>

      <Hero>{pageContent?.name}</Hero>
      <section className="bannerSection py-13">
        <div className="container">
          <BannerVideo />
          
          <div dangerouslySetInnerHTML={{__html:pageContent?.description}}>
          </div>
          </div>  
      </section>
      
</>
    );
  } else {
    return null;
  }
};

export default GuardRoute(Content);
