import { Navigate ,useNavigate} from "react-router-dom";
import { toast } from "react-toastify";
import { LOGIN_USER_SUCCESS } from "store/actions/types";
import { endpoints, request } from "utils";

export const getUploadedDocs = (callback) => async (dispatch, getState) => {
  try {
    const token = getState().auth.authData.token;
    const url = endpoints.getUploadedDocs();
    const response = await request.getRequest(url, {}, token);
    if (response.status === 200) return callback(null, response.data);
    else
      toast.error("Something Went Wrong. Please try after sometime.", {
        position: "top-right",
      });
  } catch (error) {
    console.log("Error", error);
  }
};

export const getDownloadedDocs = (callback) => async (dispatch, getState) => {
  try {
    const token = getState().auth.authData.token;
    const url = endpoints.getDownloadedDocs();
    const response = await request.getRequest(url, {}, token);
    if (response.status === 200) return callback(null, response.data);
    else
      toast.error("Something Went Wrong. Please try after sometime.", {
        position: "top-right",
      });
  } catch (error) {
    console.log("Error", error);
  }
};

export const getRecentActivity = (callback) => async (dispatch, getState) => {
  try {
    const token = getState().auth.authData.token;
    const url = endpoints.getRecentActivity();
    const response = await request.getRequest(url, {}, token);
    if (response.status === 200) return callback(null, response.data);
    else
      toast.error("Something Went Wrong. Please try after sometime.", {
        position: "top-right",
      });
  } catch (error) {
    console.log("Error", error);
  }
};

export const getRejectApproveDocStatus =
  (callback) => async (dispatch, getState) => {
    try {
      const token = getState().auth.authData.token;
      const url = endpoints.getRejectApproveDocStatus();
      const response = await request.getRequest(url, {}, token);
      if (response.status === 200) return callback(null, response.data);
      else
        toast.error("Something Went Wrong. Please try after sometime.", {
          position: "top-right",
        });
    } catch (error) {
      console.log("Error", error);
    }
  };

export const getSharedDocs = (callback) => async (dispatch, getState) => {
  try {
    const token = getState().auth.authData.token;
    const url = endpoints.getSharedDocs();
    const response = await request.getRequest(url, {}, token);
    if (response.status === 200) return callback(null, response.data);
    else
      toast.error("Something Went Wrong. Please try after sometime.", {
        position: "top-right",
      });
  } catch (error) {
    console.log("Error", error);
  }
};

export const getDashboard = (callback) => async (dispatch, getState) => {
  try {
    const token = getState().auth.authData.token;
    const url = endpoints.getDashboard();
    const response = await request.getRequest(url, {}, token);
    if (response.status === 200) return callback(null, response.data);
    else
      toast.error("Something Went Wrong. Please try after sometime.", {
        position: "top-right",
      });
  } catch (error) {
    console.log("Error", error);
  }
};

export const updateProfilePic =
  (file, callback) => async (dispatch, getState) => {
    try {
      const token = getState().auth.authData.token;
      const { user, authData } = getState().auth;
      const url = endpoints.changeProfilePic();
      const response = await request.uploadFile(url, file, token);
      if (response.status === 200) {
        const update = {
          user: { ...user, ...{ avatar: response.data["0"]["image_url"] } },
          authData: {
            ...authData,
            ...{
              customer: {
                ...user,
                ...{ avatar: response.data["0"]["image_url"] },
              },
            },
          },
        };
        localStorage.setItem("authData", JSON.stringify(update));
        dispatch({ type: LOGIN_USER_SUCCESS, payload: update });
        toast.success("Profile Pricture Updated Successfully.", {
          position: "top-right",
        });
        return callback(null, response.data);
      } else
        toast.error("Something Went Wrong. Please try after sometime.", {
          position: "top-right",
        });
    } catch (error) {
      console.log("Error", error);
    }
  };

export const updateProfileData =
  (data, callback) => async (dispatch, getState) => {
    try {
      const token = getState().auth.authData.token;
      const url = endpoints.updateProfileData();
      const response = await request.postRequest(url, data, token);
      if (response.status === 200) {
        dispatch(
          getProfileData(() => {
            callback(null, response.data);
          })
        );
      } else
        toast.error("Something Went Wrong. Please try after sometime.", {
          position: "top-right",
        });
    } catch (error) {
      console.log("Error", error);
      toast.error("Something Went Wrong. Please try after sometime.", {
        position: "top-right",
      });
    }
  };

export const getProfileData = (callback) => async (dispatch, getState) => {
  try {
    const token = getState().auth.authData.token;
    const { user, authData } = getState().auth;
    const url = endpoints.getProfileUrl();
    const response = await request.getRequest(url, {}, token);
    if (response.status === 200) {
      const update = {
        user: { ...response.data.customer },
        authData: {
          ...authData,
          ...{
            customer: { ...response.data.customer },
          },
        },
      };
      localStorage.setItem("authData", JSON.stringify(update));
      dispatch({ type: LOGIN_USER_SUCCESS, payload: update });
      return callback();
    } else
      toast.error("Something Went Wrong. Please try after sometime.", {
        position: "top-right",
      });
  } catch (error) {
    console.log("Error", error);
    toast.error("Something Went Wrong. Please try after sometime.", {
      position: "top-right",
    });
    return false;
  }
};

export async function copyToClipboard(textToCopy) {
  // Navigator clipboard api needs a secure context (https)
  if (navigator.clipboard && window.isSecureContext) {
    await navigator.clipboard.writeText(textToCopy);
  } else {
    // Use the 'out of viewport hidden text area' trick
    const textArea = document.createElement("textarea");
    textArea.value = textToCopy;

    // Move textarea out of the viewport so it's not visible
    textArea.style.position = "absolute";
    textArea.style.left = "-999999px";

    document.body.prepend(textArea);
    textArea.select();

    try {
      document.execCommand("copy");
    } catch (error) {
      console.error(error);
    } finally {
      textArea.remove();
    }
  }
}

export const shareDoc = (data, callback) => async (dispatch, getState) => {
  try {
    const token = getState().auth.authData.token;
    const url = endpoints.shareDoc();
    const response = await request.postRequest(url, { slug: data.id }, token);
    if (response.status === 200 && response.data.success)
      return callback(null, response.data);
    else if (response.status === 200 && !response.data.success)
      return toast.error("File is not availabe to share/download", {
        position: "top-right",
      });
    else
      toast.error("Something Went Wrong. Please try after sometime.", {
        position: "top-right",
      });
  } catch (error) {
    console.log("Error", error);
    toast.error("Something Went Wrong. Please try after sometime.", {
      position: "top-right",
    });
    return callback(error, null);
  }
};

export const downloadDoc = (data, callback) => async (dispatch, getState) => {
  try {
    const token = getState().auth.authData.token;
    const url = endpoints.downloadDoc();
    const response = await request.postRequest(url, { slug: data.id }, token);
    if (response.status === 200 && response.data.success)
      return callback(null, response.data);
    else if (response.status === 200 && !response.data.success)
      return toast.error("File is not availabe to share/download", {
        position: "top-right",
      });
    else
      return toast.error("Something Went Wrong. Please try after sometime.", {
        position: "top-right",
      });
  } catch (error) {
    console.log("Error", error);
    toast.error("Something Went Wrong. Please try after sometime.", {
      position: "top-right",
    });
    return callback(error, null);
  }
};

export const getPageContent =
  (slug, callback) => async (dispatch, getState) => {
    try {
      const url = endpoints.getPageContent();
      const response = await request.postRequestWithoutAuth(url, {
        slug: `${slug}`,
      });
      if (response.status === 200 && !response.data.error)
        return callback(null, response.data);
      else
        toast.error("Something Went Wrong. Please try after sometime.", {
          position: "top-right",
        });
    } catch (error) {
      console.log("Error", error);
      toast.error("Something Went Wrong. Please try after sometime.", {
        position: "top-right",
      });
      return callback(error, null);
    }
  };

export const sendMessage = (data, callback) => async (dispatch, getState) => {
  try {
    const url = endpoints.sendMessage();
    const response = await request.postRequestWithoutAuth(url, data);

    
    
    if (response.status === 200 && !response.data.error) {

      toast.success("Thanks for your message. we will respond soon", {
        position: "top-right",
      });
     
     window.grecaptcha.reset();

      return callback(null, response.data);

    } else {
      
      toast.error(response.data.message, {
        position: "top-right",
      });
      return callback(null, response.data); // condition applied by gaurav to reactivate submit button
    }
  } catch (error) {
    console.log("Error", error);
    toast.error("Something Went Wrong. Please try after sometime.", {
      position: "top-right",
    });
    return callback(error, null);
  }
};



export const updatePassword = (data, callback) => async (dispatch, getState) => {   

  try {
    const url = endpoints.resetPassowrd();
    const response = await request.postRequestWithoutAuth(url, data);

    
    if (response.status === 200 && !response.data.error) {

      toast.success("Password Reset Successfully", {
        position: "top-right",
      });
            
      return callback(null, response.data);

    } else {
      
      toast.error(response.data.message, {
        position: "top-right",
      });
      return callback(null, response.data); // condition applied by gaurav to reactivate submit button
    }
  } catch (error) {
    console.log("Error", error);
    toast.error("Something Went Wrong. Please try after sometime.", {
      position: "top-right",
    });
    return callback(error, null);
  }
};

  export const getHomePageContent = (callback) => async (dispatch, getState) => {
    try {
      
      const url = endpoints.getHomePageContent();
      const response = await request.getRequest(url, {}, "");
      if (response.status === 200) return callback(null, response.data);
      else
        toast.error("Something Went Wrong. Please try after sometime.", {
          position: "top-right",
        });
    } catch (error) {
      console.log("Error", error);
    }
  };
