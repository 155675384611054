const emailRegex =
  /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;

export default class Validator {
  fields = {};
  rules = {};
  errors = {};
  names = {};
  compRef = null;
  formName = null;
  PHONE_NUMBER_LENGTH = 10;

  constructor(data, compRef = null, formName) {
    for (let field in data) {
      let value = "";
      if ("value" in data[field]) {
        value = data[field].value;
      }
      this.fields[field] = value;

      if ("rules" in data[field]) {
        this.rules[field] = data[field].rules;
        this.errors[field] = [];
      }
      if ("name" in data[field]) {
        this.names[field] = data[field].name;
      }

      Object.defineProperty(this, field, {
        get: function () {
          return this.fields[field];
        },

        set: function (val) {
          this.fields[field] = val;
          this.errors[field] = [];
        },
      });
    }

    this.compRef = compRef;
    this.formName = formName;
  }

  validate() {
    return new Promise((resolve, reject) => {
      let hasError = false;
      for (let field in this.rules) {
        this.errors[field] = [];

        for (let r of this.rules[field]) {
          r = r.split(":");

          const rule = r[0];
          /* if (rule === 'optional') {
						console.log('');
					} else */ if (rule === "required") {
            if (this.fields[field] === "") {
              this.errors[field].push("This field is required");
              hasError = true;
            }
          } else if (rule === "email") {
            if (this.fields[field] && !emailRegex.test(this.fields[field])) {
              this.errors[field].push("Please provide valid email");
              hasError = true;
            }
          } else if (rule === "phone") {
            if (
              this.fields[field] &&
              (!/^\d+$/.test(this.fields[field]) ||
                this.fields[field].length !== this.PHONE_NUMBER_LENGTH)
            ) {
              this.errors[field].push("Please provide correct phone number");
              hasError = true;
            }
          }
          // else if (rule === 'match') {
          // 	const matchField = r[1];
          // 	if(this.fields[field] && this.fields[matchField] && this.fields[field] !== this.fields[matchField]){
          // 		this.errors[field].push(`Passowrd doesn't match.`)
          // 		hasError = true;
          // 	}
          // }
          else if (rule === "requiredIf") {
            const ruleCondition = r[1].split(",");
            console.log(ruleCondition);
            let conditionOnField = ruleCondition[0],
              condition = ruleCondition[1];

            let conditionMet = false;
            switch (condition) {
              //  case '>':   return post > value;
              // 	case '<':   return post < value;
              // 	case '>=':  return post >= value;
              // 	case '<=':  return post <= value;
              // 	case '==':  return post == value;
              // 	case '!=':  return post != value;
              // 	case '===': return post === value;
              // 	case '!==': return post !== value;
              // 	case '!=': return post != value
              // 		conditionMet = this.fields[conditionOnField] != conditionValue;
              // 		break;
              case "=":
                if (this.fields[conditionOnField] !== "") {
                  conditionMet =
                    this.fields[conditionOnField] === this.fields[field];
                }
                break;
            }

            if (
              this.fields[conditionOnField] !== "" &&
              this.fields[field] === ""
            ) {
              this.errors[field].push("This field is required");
              hasError = true;
            } else if (this.fields[conditionOnField] !== "" && !conditionMet) {
              this.errors[field].push(
                `${this.names[conditionOnField]} should match ${this.names[field]}`
              );
              hasError = true;
            }
          }
          /* else if (rule === 'url') {
						if (
							this.fields[field] &&
							!/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g.test(this.fields[field]) // eslint-disable-line
						) {
							this.errors[field].push('Please provide valid url');
							hasError = true;
						}
					} else if (rule === 'numeric') {
						if (this.fields[field] && !/^\d+$/.test(this.fields[field])) {
							this.errors[field].push('Please provide numeric input');
							hasError = true;
						}
					} else if (rule === 'float') {
						if (this.fields[field] && !/^[0-9]\d*((\.\d{1,2})?)$/.test(this.fields[field])) {
							this.errors[field].push('Please provide upto two place decimal input');
							hasError = true;
						}
					} else if (rule === 'requiredIf') {
						const ruleCondition = r[1].split(','),
							conditionOnField = ruleCondition[0],
							condition = ruleCondition[1],
							conditionValue = ruleCondition[2];

						let conditionMet = false;
						switch (condition) {
							/* case '>':   return post > value;
							case '<':   return post < value;
							case '>=':  return post >= value;
							case '<=':  return post <= value;
							case '==':  return post == value;
							case '!=':  return post != value;
							case '===': return post === value;
							case '!==': return post !== value; * /
							case '!=': //return post != value:
								conditionMet = this.fields[conditionOnField] != conditionValue;
								break;
							case '=':
								conditionMet = this.fields[conditionOnField] == conditionValue;
								break;
						}

						if (conditionMet && this.fields[field] === '') {
							this.errors[field].push('This field is required');
							hasError = true;
						}
					} else if (rule === 'max') {
						if (this.rules[field].includes('upload')) {
							if (this.fields[field].length > r[1]) {
								this.errors[field].push(`Please upload max ${r[1]} file(s)`);
								hasError = true;
							}
						}
					} else if (rule === 'allowed') {
						if (this.rules[field].includes('upload')) {
							for (const file of this.fields[field]) {
								if (!r[1].includes(file.type)) {
									this.errors[field].push(`Please upload valid file`);
									hasError = true;
								}
							}
						}
					} */
        }
      }

      if (this.compRef) {
        this.compRef.setState({
          [`${this.formName}`]: this.compRef.state[`${this.formName}`],
        });
      }
      if (!hasError) {
        console.log(this.errors, hasError);
        resolve();
      } else {
        console.log(this.errors, hasError);
        reject({ validation: false, message: "Validation Fails" });
      }
    });
  }

  /* setUploadValue(e, field) {
		this.fields[field] = e.target.files || e.dataTransfer.files;
	} */

  hasError(field) {
    return !!this.errors[field].length;
  }

  getErrors(field) {
    return this.errors[field].join(", ");
  }

  renderError(field) {
    return this.hasError(field)
      ? `<span class="error">${this.getErrors(field)}</span>`
      : "";
  }

  handleChange = (e) => {
    console.log(e.target.value, e.target.id);
    this.compRef.state[`${this.formName}`].fields[e.target.id] = e.target.value;
    this.compRef.setState({
      [`${this.formName}`]: this.compRef.state[`${this.formName}`],
    });
  };
}
