

import React, { useEffect, useState } from "react";
import { GuardRoute } from 'hoc';
import { BannerVideo } from 'components';
import { img01, img02, img03, img04, img05, img06, bg01, bg03 } from 'components/Images';
import { Router } from "router";
import { NavLink, Link, useNavigate } from "react-router-dom";

import { getHomePageContent } from "actions/common";
import { useDispatch } from "react-redux";

const Home = () => {


	const [HomePageContent, setHomePageContent] = useState({});
	const dispatch = useDispatch();
  
	useEffect(() => {
	  dispatch(
		getHomePageContent((err, response) => {
		  
			console.log(response);
			response?.pages &&
			  response?.pages.length > 0 &&
			  setHomePageContent([...response?.pages]);

		})
	  );
	}, []);


	
	return (
		<>
			<section className="bannerSection landingBanner py-13">
				<div className="container">
					<BannerVideo />
					<div className="row gx-9">
						<div className="col-6">
							<h1 className="banner-title gradient-text mb-5 display-2 fw-bold">
								White Glove
								<br/>
								And Fully Managed Cyber Security
							</h1>
							<div className="banner-text lead">
								<p className="mb-6 lh-lg text-white">
								We license best-in-class cybersecurity products that you wouldn't be able to access directly from a large vendor and create a custom stack for each of your clients.
								</p>

								<NavLink
								to={Router.getRoute("contact")}								
								end
								>
								<button className="btn btn-primary">
									Explore our MDR
									<ion-icon className="icon" role="img" name="chevron-forward-outline" />
								</button>
								</NavLink>
							</div>
						</div>
					</div>
				</div>
			</section>
		 
			

{HomePageContent && HomePageContent.length > 0 && HomePageContent.map((data) => {

const regex = /(<([^>]+)>)/ig;
const description = data.body; //data.body.replace(regex, '');

 if(data.image_location=="right"){
		return (<section className="bannerSection landingBanner py-13" style={{ backgroundImage: `url(${data.background_image})` }}>
				<div className="container">

					{data.background_image=="null"}{
						<BannerVideo />
					}
					
					{data.image_location=="right"}{					

					<div className="row gx-9 align-items-center">
						<div className="col-6">
							<h1 className="banner-title gradient-text mb-5 display-6 fw-bold text-uppercase">{data.title}</h1>						
							
							<p className="mb-6 lead lh-lg text-white"  dangerouslySetInnerHTML={{ __html: description }}
            ></p>

							<a href={data.slug}	
							>
							<button className="btn btn-primary">
								Read More
								<ion-icon className="icon" role="img" name="chevron-forward-outline" />
							</button>
							</a>
						</div>
						<div className="col-6">
							<img src={data.image} alt="img03" className="img-fluid" />
						</div>
					</div>
				} 

				

				</div>
			</section>)
 	} else {
		return (<section className="bannerSection landingBanner py-13" style={{ backgroundImage: `url(${data.background_image})` }}>
				<div className="container">

					{data.background_image=="null"}{
						<BannerVideo />
					}
					
				 				

					<div className="row gx-9 align-items-center">

						<div className="col-6">
							<img src={data.image} alt="img03" className="img-fluid" />
						</div>
						<div className="col-6">
							<h1 className="banner-title gradient-text mb-5 display-6 fw-bold text-uppercase">{data.title}</h1>						
							
							<p className="mb-6 lead lh-lg text-white"  dangerouslySetInnerHTML={{ __html: description }}
            ></p>

							<a href={data.slug}	
							>
							<button className="btn btn-primary">
								Read More
								<ion-icon className="icon" role="img" name="chevron-forward-outline" />
							</button>
							</a>
						</div>
						
					</div>				

				</div>
			</section>)
	}
})}
		

		 
	 


			{/*
			<section className="bannerSection landingBanner py-13">
				<div className="container">
					<BannerVideo />

					<div className="row flex-row-reverse gx-9 align-items-center">
						<div className="col-6">
							<h1 className="banner-title gradient-text mb-5 display-6 fw-bold text-uppercase">Forrester Wave</h1>
							<p className="mb-6 lead lh-lg text-white">
								Lorem ipsum, or lipsum as it is sometimes known, is dummy text used in laying out print, graphic or web designs. The passage is
								attributed to an unknown typesetter in the 15th century who is thought to have scrambled parts of Cicero's De Finibus Bonorum
								et Malorum for use in a type specimen book. The passage is attributed to an unknown typesetter in the 15th century who is
								thought to have scrambled parts of Cic ero's De Finibus Bonorum et Malorum for use in a type specimen book.
							</p>
							<NavLink
								to={Router.getRoute("forresterwave")}								
								end
							>
							<button className="btn btn-primary">
								Read More
								<ion-icon className="icon" role="img" name="chevron-forward-outline" />
							</button>
							</NavLink>
						</div>
						<div className="col-6">
							<img src={img05} alt="img05" className="img-fluid" />
						</div>
					</div>
				</div>
			</section>
	*/}
		
		</>
	);
};

export default GuardRoute(Home);
