import React from "react";
import { NavLink, Link, useNavigate } from "react-router-dom";
import { Router } from "router";

import {
  facebookIcon,
  linkedinIcon,
  instagramIcon,
  twitterIcon,
  mailIcon,
  phoneIcon,
  mapicon,
} from "components/Images";

const Footer = () => {
  return (
    <footer className="pt-4" style={{ position: "relative" }}>
      <div className="container">
        <div className="row justify-content-between">
          <div className="col-3 py-4">
            <h5 className="mb-5 text-white fw-normal">About Company</h5>
            <p className="mb-6 small">
            Trust, consistency & integrity are core parts of how we operate. We deliver on our promises and always put your best interests before our own convenience. As our commitment to this, we operate on flat margins.
            </p>
            <ul className="list-inline mb-0">
              <li className="list-inline-item m-r-12">
                <a href="">
                  <img
                    className="icon"
                    src={facebookIcon}
                    alt="facebook_icon"
                  />
                </a>
              </li>
              <li className="list-inline-item m-r-13">
                <a href="">
                  <img className="icon" src={twitterIcon} alt="twitter_icon" />
                </a>
              </li>
              <li className="list-inline-item m-r-12">
                <a href="">
                  <img
                    className="icon"
                    src={instagramIcon}
                    alt="instagram_icon"
                  />
                </a>
              </li>
              <li className="list-inline-item">
                <a href="">
                  <img
                    className="icon"
                    src={linkedinIcon}
                    alt="linkedin_icon"
                  />
                </a>
              </li>
            </ul>
          </div>
          <div className="col-3 py-4">
            <h5 className="mb-5 text-white fw-normal">Quick Links</h5>
            <nav className="small">
              <ul className="nav small row g-0 row-cols-2 mb-0">
                <li className="nav-item">
                <NavLink to={Router.getRoute("home")} className="nav-link" end>
                  {Router.getText("home")}
                </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink
                  to={Router.getRoute("whatWeDo")}
                  className="nav-link"
                  end
                >
                  {Router.getText("whatWeDo")}
                </NavLink>
                </li>
                <li className="nav-item">
                <NavLink to={Router.getRoute("services")} className="nav-link" end>
                  {Router.getText("services")}
                </NavLink>
                </li>
                <li className="nav-item">
                <NavLink
                  to={Router.getRoute("faq")}
                  className="nav-link"
                  end
                >
                  {Router.getText("faq")}
                </NavLink>
                </li>
                <li className="nav-item">
                 <NavLink
                  to={Router.getRoute("solutions")}
                  className="nav-link"
                  end
                >
                  {Router.getText("solutions")}
                </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink
                    to={Router.getRoute("contact")}
                    className="nav-link"
                    end
                  >
                    {Router.getText("contact")}
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink
                    to={Router.getRoute("about")}
                    className="nav-link"
                    end
                  >
                    {Router.getText("about")}
                  </NavLink>
                </li>
                 
              </ul>
            </nav>
          </div>
          <div className="col-3 py-4">
            <h5 className="mb-5 text-white fw-normal">Contact</h5>
            {/* <p className="mb-6 small">
              Lorem ipsum, or lipsum as it is sometimes known, is dummy text
              used in laying out print
            </p> */}
            <nav className="small">
              <ul className="nav small row g-0 row-cols-1 mb-0">

            

                <li className="nav-item">
                  <a href="" className="nav-link">
                    <img
                      className="icon me-2"
                      src={phoneIcon}
                      alt="phone_icon"
                    />
                    &nbsp;<a href="tel:(855) 203-7111">(855) 203-7111</a>
                  </a>
                </li>
                <li className="nav-item">
                  <a href="" className="nav-link">
                    <img className="icon me-2" src={mailIcon} alt="mail_icon" />
                    &nbsp;<a href="mailto:info@sppartners.co">info@sppartners.co</a>
                  </a>
                </li>

                <li className="nav-item">
                  <a href="https://goo.gl/maps/fsGwkbA3YPijeDQn7" target="_blank" className="nav-link" >
                    <img
                      className="icon me-2"
                      src={mapicon}
                      alt="map_icon"
                      width={20}
                    />
                    
7301 RR 620N Suite 155-161 Austin, TX 78726
                  </a>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
      <div className="border-top small py-4 mt-4">
        <div className="container">
          Copyright {new Date().getFullYear()}&nbsp;
          <span className="text-primary">FutureSafe </span> | Design and Develop By <a href="https://www.blackbulltechnosoft.com/">BlackbullTechnosoft</a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
