import { endpoints, request } from "utils";
import {
  LOGIN_USER_SUCCESS,
  LOGOUT_USER_SUCCESS,
  UPDATE_USER_SUCCESS,
} from "./types";
import { toast } from "react-toastify";
import { updateProfileData, updateProfilePic } from "actions/common";

export const loginUser = (email, password) => async (dispatch) => {
  const payload = { email, password };
  // localStorage.setItem('loggedInUser', JSON.stringify(payload));
  const url = endpoints.loginUrl();
  const response = await request.postRequest(
    url,
    JSON.stringify(payload),
    null,
    false
  );
  console.log(response.data);
  if (!response.data.error) {
    const update = {
      user: { ...response.data?.data?.customer },
      authData: { ...response.data?.data },
    };
    localStorage.setItem("authData", JSON.stringify(update));
    dispatch({ type: LOGIN_USER_SUCCESS, payload: update });
    toast.success("Login Successfully", { position: "top-right" });
  } else if (response.data.error) {
    toast.error(response.data.message, { position: "top-right" });
  }
};

export const logoutUser = (navigate) => async (dispatch, getState) => {
  const token = getState().auth.authData.token;
  const url = endpoints.logoutUrl();
  const response = await request.postRequest(url, {}, token, true);
  if (response.status === 200) {
    dispatch({ type: LOGOUT_USER_SUCCESS });
    localStorage.removeItem("authData");
    navigate("login");
    toast.success("Logout Successfully", { position: "top-right" });
  }
};

export const updateProfile = (data, callback) => (dispatch) => {
  dispatch(
    updateProfileData(data, (err, result) => {
      if (result) {
        toast.success("Profile updated successfully.", {
          position: "top-right",
        });
      }
      callback();
    })
  );
};

export const updateProfileImage = (file, callback) => (dispatch) => {
  dispatch(
    updateProfilePic(file, (err, result) => {
      if (result) {
        toast.success("Profile Image updated successfully.", {
          position: "top-right",
        });
      }
      callback();
    })
  );
};

export const resetPassowrd = (email) => async (dispatch) => {
  const payload = { email };
  // localStorage.setItem('loggedInUser', JSON.stringify(payload));
  const url = endpoints.forgotPassword();
  const response = await request.postRequestWithoutAuth(
    url,
    JSON.stringify(payload)
  );
  console.log(response.data);
  if (!response.data.error) {
    toast.success("Reset link sent to your email.", { position: "top-right" });
  } else if (response.data.error) {
    toast.error(response.data.message, { position: "top-right" });
  }
};
