import React, { Component, useCallback } from "react";
import { Validator } from "classes";
import { DynamicHtml, BannerVideo } from "components";
import { connect } from "react-redux";
import { loginUser, resetPassowrd } from "store/actions";
import { GuardRoute } from "hoc";
import { compose } from "redux";
import { arrowRight, logo } from "components/Images";
import Particles from "react-particles";
import { loadFull } from "tsparticles";
// import Captcha from "components/GoogleRecaptch";
import { googleRecaptchSiteKey } from "config/dev.config";
import { toast } from "react-toastify";

class Login extends Component {
  state = {
    form: new Validator(
      {
        loginEmail: {
          rules: ["required"],
        },
        loginPassword: {
          rules: ["required"],
        },
      },
      this,
      "form"
    ),
    isLogin: true,
    tokenCaptcha: null,
    forgotPasswordForm: new Validator(
      {
        email: {
          rules: ["required"],
        },
      },
      this,
      "forgotPasswordForm"
    ),
    scriptLoaded: false,
  };
  submitLoginForm = (e) => {
    e.preventDefault();
    this.state.form.validate().then(() => {
      this.props
        .loginUser(
          this.state.form.fields.loginEmail,
          this.state.form.fields.loginPassword
        )
        .then(() => {});
    });
    /* .catch(() => {
				console.log(this.state.form.hasError('loginEmail'));
			}); */
  };

  submitForgotPasswordForm = (e) => {
    e.preventDefault();
    const captchaResponse = window.grecaptcha.getResponse();
    if (
      captchaResponse == "" ||
      captchaResponse == undefined ||
      captchaResponse.length == 0
    ) {
      toast.error("Please verify captcha", { position: "top-right" });
      return false;
    }
    this.state.forgotPasswordForm.validate().then(() => {
      this.props
        .resetPassowrd(this.state.forgotPasswordForm.fields.email)
        .then(() => {
          window.grecaptcha.reset();
          this.switchLoginState();
        });
    });
    /* .catch(() => {
				console.log(this.state.form.hasError('loginEmail'));
			}); */
  };

  switchLoginState = () => {
    this.setState({
      ...this.state,
      isLogin: !this.state.isLogin,
      scriptLoaded: false,
    });
  };

  particlesInit = async (engine) => {
    console.log(engine);
    await loadFull(engine);
  };

  particlesLoaded = async (container) => {
    await console.log(container);
  };

  onloadCallback() {
    window.grecaptcha.render(document.getElementById("googleCaptcha"), {
      sitekey: googleRecaptchSiteKey,
      callback: this.captchaCallBack.bind(this),
    });
  }

  captchaCallBack(token) {
    console.log("token", token);
  }

  componentDidUpdate() {
    if (!this.state.isLogin && !this.state.scriptLoaded) {
      let scriptEle = document.createElement("script");

      scriptEle.setAttribute(
        "src",
        `https://www.google.com/recaptcha/api.js?onload=onloadCallback&render=explicit`
      );
      scriptEle.setAttribute("type", "text/javascript");
      scriptEle.setAttribute("async", true);

      document.body.appendChild(scriptEle);

      // success event
      scriptEle.addEventListener("load", () => {
        this.setState({
          ...this.state,
          scriptLoaded: true,
        });
        window.onloadCallback = this.onloadCallback.bind(this);
        console.log("File loaded");
      });
      // error event
      scriptEle.addEventListener("error", (ev) => {
        console.log("Error on loading file", ev);
      });
    }
  }
  render() {
    return (
      <div style={{ position: "relative" }}>
        {/* <BannerVideo /> */}
        <Particles
          id="tsparticles"
          init={this.particlesInit}
          loaded={this.particlesLoaded}
          container={"particleContainerRef"}
          options={{
            fullScreen: { enable: false },
            background: {
              color: {
                value: "black",
              },
            },
            fpsLimit: 120,
            detectRetina: true,
            interactivity: {
              events: {
                onClick: {
                  enable: true,
                  mode: "repulse",
                },
                onHover: {
                  enable: true,
                  mode: "repulse",
                },
                resize: false,
              },
              modes: {
                push: {
                  quantity: 4,
                },
                repulse: {
                  distance: 130,
                  duration: 1,
                },
              },
            },
            particles: {
              color: {
                value: "#037BCB",
              },
              links: {
                color: "#ffffff",
                distance: 80,
                enable: false,
                opacity: 1,
                width: 1,
              },
              collisions: {
                enable: true,
              },
              move: {
                enable: true,
                speed: 6,
                direction: "bottom-left",
                random: false,
                straight: false,
                out_mode: "out",
                bounce: false,
                attract: {
                  enable: false,
                  rotateX: 600,
                  rotateY: 1200,
                },
              },
              number: {
                value: 400,
                density: {
                  enable: true,
                  value_area: 800,
                },
              },
              opacity: {
                value: 0.9,
              },
              shape: {
                type: "circle",
              },
              size: {
                value: { min: 3, max: 5 },
              },
            },
          }}
        />
        <div className="row gx-9">
          <div className="col-6 mx-auto">
            <div className="card login-forgot-card">
              <div className="card-body p-8 d-flex align-items-center">
                <div className="row gx-15 flex-grow-1">
                  <div className="col-10 mx-auto">
                    <div className="text-center">
                      <img src={logo} alt="Future Safe" height={68} />
                    </div>
                    <h5 className="card-title text-uppercase text-center my-4 text-dark fw-bold pb-3">
                      <span className="small">
                        {this.state.isLogin ? "Login" : "Forgot Password"}
                      </span>
                    </h5>
                    {!this.state.isLogin && (
                      <p className="mb-6 small text-center">
                        No worries we'll send you reset instructions.
                      </p>
                    )}
                    {this.state.isLogin && (
                      <form onSubmit={this.submitLoginForm}>
                        <div className="row gy-5 gx-3">
                          <div className="col-12">
                            <label
                              htmlFor="loginEmail"
                              className="form-label text-dark"
                            >
                              Username / Email{" "}
                              <span className="text-danger">*</span>
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              onChange={this.state.form.handleChange}
                              name="loginEmail"
                              id="loginEmail"
                              placeholder="Username / Email"
                            />
                            <DynamicHtml>
                              {this.state.form.renderError("loginEmail")}
                            </DynamicHtml>
                          </div>
                          <div className="col-12">
                            <label
                              htmlFor="loginPassword"
                              className="form-label text-dark"
                            >
                              Password <span className="text-danger">*</span>
                            </label>
                            <input
                              type="password"
                              className="form-control"
                              onChange={this.state.form.handleChange}
                              name="loginPassword"
                              id="loginPassword"
                              placeholder="Password"
                            />
                            <DynamicHtml>
                              {this.state.form.renderError("loginPassword")}
                            </DynamicHtml>
                          </div>
                          <div className="col-12">
                            <div className="form-check small text-dark mb-0">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                defaultValue=""
                                id="rememberMe"
                              />
                              <label
                                className="form-check-label"
                                htmlFor="rememberMe"
                              >
                                Remember Me
                              </label>
                            </div>
                          </div>
                          <div className="col-auto">
                            <button
                              type="submit"
                              className="btn btn-primary w-100 px-5 text-uppercase"
                            >
                              <span className="fw-medium"> Login </span>
                            </button>
                          </div>
                          <div className="col-auto">
                            <a
                              className="btn btn-link fw-medium text-dark px-0"
                              onClick={this.switchLoginState}
                            >
                              <span className="fw-medium">
                                Forget Password?
                              </span>
                            </a>
                          </div>
                        </div>
                      </form>
                    )}
                    {!this.state.isLogin && (
                      <form onSubmit={this.submitForgotPasswordForm}>
                        <div class="row gy-5">
                          <div class="col-12">
                            <label
                              htmlFor="email"
                              className="form-label text-dark"
                            >
                              Email <span className="text-danger">*</span>
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              onChange={
                                this.state.forgotPasswordForm.handleChange
                              }
                              name="email"
                              id="email"
                              value={this.state.forgotPasswordForm.fields.email}
                              placeholder="Enter your email"
                            />
                            <DynamicHtml>
                              {this.state.forgotPasswordForm.renderError(
                                "email"
                              )}
                            </DynamicHtml>
                          </div>
                          <div className="col-12 mt-5">
                            {/* <Captcha setToken={setToken} /> */}
                            <div
                              id="googleCaptcha"
                              class="g-recaptcha"
                              // data-sitekey={googleRecaptchSiteKey}
                              // data-callback="onSubmit"
                              // data-size="invisible"
                            ></div>
                          </div>
                          <div class="col-6">
                            <button type="submit" class="btn btn-primary w-100">
                              Reset Password
                            </button>
                          </div>
                          <div class="col-6 text-start">
                            <a
                              class="btn btn-link"
                              onClick={this.switchLoginState}
                            >
                              <img
                                class="icon ms-1"
                                src={arrowRight}
                                alt="arrow-left-blue_icon"
                              />
                              <span>Back to Login</span>
                            </a>
                          </div>
                        </div>
                      </form>
                    )}
                  </div>
                  {/* register part */}
                  {/* <div class="col-6 mx-auto border-start">
              <h5
                class="card-title text-uppercase text-center my-4 text-dark fw-bold pb-3"
              >
                <span class="small">Register</span>
              </h5>
              <p class="mb-6 small text-center">
                No worries we'll send you reset instructions.
              </p>
              <form action="">
                <div class="row gy-5 gx-3">
                  <div class="col-12">
                    <label for="username" class="form-label text-dark">
                      Username <span class="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      class="form-control"
                      id="username"
                      placeholder="Username"
                    />
                  </div>
                  <div class="col-12">
                    <label for="email" class="form-label text-dark">
                      Email Address <span class="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      class="form-control"
                      id="email"
                      placeholder="email"
                    />
                  </div>
                  <div class="col-12">
                    <label for="password" class="form-label text-dark">
                      Password <span class="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      class="form-control"
                      id="password"
                      placeholder="Password"
                    />
                  </div>
                  <div class="col-auto">
                    <button
                      type="submit"
                      class="btn btn-primary w-100 px-5 text-uppercase"
                    >
                      <span class="fw-medium"> Register </span>
                    </button>
                  </div>
                </div>
              </form>
            </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

/* const mapStateToProps = ({ auth }) => {
	return {
		auth,
	};
}; */

const mapDispatchToProps = (dispatch) => {
  return {
    loginUser: (email, password) => dispatch(loginUser(email, password)),
    resetPassowrd: (email) => dispatch(resetPassowrd(email)),
  };
};

export default compose(GuardRoute, connect(null, mapDispatchToProps))(Login);
