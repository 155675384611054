const baseUrl = `http://16.16.99.138:8097/api/auth/`;

// for local host
// const googleRecaptchSiteKey = `6LfBXKclAAAAAE9WKQiJC2S1IvMGZm-K0VF-go5M`;
// const googleRecaptchSecretKey = `6LfBXKclAAAAAA53pABb69qcwAyclcbhupleNfRC`;

// for development server
const googleRecaptchSiteKey = `6Lfg8KslAAAAAPx6rgcukR3aLJ4iSXMUr7iEqwwb`;
const googleRecaptchSecretKey = `6Lfg8KslAAAAAMZ-bXnu3x7h1huRx_-S-LYPSAKc`;

export { baseUrl, googleRecaptchSiteKey, googleRecaptchSecretKey };
