import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { Router } from 'router';
import { Navbar, Footer } from 'layouts';
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from 'react-toastify';

const App = () => {
	return (
		<>
			{Router.isRouteActive('login') ? <div id="particles-js"></div> : ''}
			<Navbar />
			<ToastContainer/>
			<Routes>
				<Route path={Router.getRoute('home')} element={Router.injectComponent('home')} />
				<Route path={Router.getRoute('whatWeDo')} element={Router.injectComponent('whatWeDo')} />
				
				<Route path={Router.getRoute('solutions')} element={Router.injectComponent('solutions')} />

				{/*content pages added by gaurav*/}
				<Route path={Router.getRoute('stropthreat')} element={Router.injectComponent('stropthreat')} />
				<Route path={Router.getRoute('homeendpointxdr')} element={Router.injectComponent('homeendpointxdr')} />
				<Route path={Router.getRoute('cloudsecurity')} element={Router.injectComponent('cloudsecurity')} />
				<Route path={Router.getRoute('threatintelligence')} element={Router.injectComponent('threatintelligence')} />
				<Route path={Router.getRoute('forresterwave')} element={Router.injectComponent('forresterwave')} />
				<Route path={Router.getRoute('company')} element={Router.injectComponent('company')} />
				<Route path={Router.getRoute('services')} element={Router.injectComponent('services')} />
				<Route path={Router.getRoute('faq')} element={Router.injectComponent('faq')} />
				<Route path={Router.getRoute('contact')} element={Router.injectComponent('contact')} />
				<Route path={Router.getRoute('about')} element={Router.injectComponent('about')} />
				<Route path={Router.getRoute('vendors')} element={Router.injectComponent('vendors')} />
				<Route path={Router.getRoute('sitemap')} element={Router.injectComponent('sitemap')} />
				<Route path={Router.getRoute('resetPassword')} element={Router.injectComponent('resetPassword')} />

				{/*content pages added by gaurav end here*/}

				<Route path={Router.getRoute('resources')} element={Router.injectComponent('resources')} />
				<Route path={Router.getRoute('partners')} element={Router.injectComponent('partners')} />
				<Route path={Router.getRoute('company')} element={Router.injectComponent('company')} />
				<Route path={Router.getRoute('login')} element={Router.injectComponent('login')} />
				<Route path={Router.getRoute('dashboard')} element={Router.injectComponent('dashboard')} />
				<Route path={Router.getRoute('uploadedDocs')} element={Router.injectComponent('uploadedDocs')} />
				<Route path={Router.getRoute('downloadHistory')} element={Router.injectComponent('downloadHistory')} />
				<Route path={Router.getRoute('sharedDocs')} element={Router.injectComponent('sharedDocs')} />
				<Route path={Router.getRoute('downloadedDocs')} element={Router.injectComponent('downloadedDocs')} />
				<Route path={Router.getRoute('history')} element={Router.injectComponent('history')} />
				<Route path={Router.getRoute('rau')} element={Router.injectComponent('rau')} />				
				<Route path={Router.getRoute('docstatus')} element={Router.injectComponent('docstatus')} />
				<Route path={Router.getRoute('editProfile')} element={Router.injectComponent('editProfile')} />
				<Route path={Router.getRoute('docsManagement')} element={Router.injectComponent('docsManagement')} />
				<Route path={"*"} element={Router.injectComponent("pageNotFound")} />
				
			</Routes>
			<Footer />
		</>
	);
};

export default App;
