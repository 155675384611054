import React, { useEffect, useState } from "react";
import { GuardRoute } from "hoc";
import { Sidemenu, Hero } from "layouts";
import { Datetime, BannerVideo, TableCaption, Table } from "components";
import {
  pdfIcon,
  zipIcon,
  folderIcon,
  textIcon,
  rarIcon,
} from "components/Images";
import { useDispatch } from "react-redux";
import { getUploadedDocs } from "actions/common";

const tableHeaderData = ["File Name", "Download Time"];

const DownloadHistory = () => {
  const [uploadedDocs, setUploadedDocs] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      getUploadedDocs((err, response) => {
        console.log(response);
        response?.documents &&
          response?.documents.length > 0 &&
          setUploadedDocs([...response?.documents]);
      })
    );
  }, []);
  return (
    <>
      <Hero>Downloaded Documents</Hero>
      <section className="bannerSection py-13">
        <div className="container">
          <BannerVideo />
          <div className="row gx-9">
            <div className="col-auto">
              <Sidemenu />
            </div>
            <div className="col">
              <TableCaption>List of Download history</TableCaption>
              {uploadedDocs && uploadedDocs.length > 0 && (
                <Table
                  tableHeaderData={tableHeaderData}
                  tableData={uploadedDocs}
                />
              )}
              {/* <table className="table table-borderless mb-0">
								<thead className="bg-primary text-white border-0">
									<tr>
										<th>File Name</th>
										<th>Download Time</th>
									</tr>
								</thead>
								<tbody className="small align-middle">
									<tr>
										<td>
											<img className="me-2" src={pdfIcon} alt="pdf_icon" />
											&nbsp;Chat Application
										</td>
										<td>
											<Datetime time="9:20AM" />
										</td>
									</tr>
									<tr>
										<td>
											<img className="me-2" src={zipIcon} alt="zip_icon" />
											&nbsp;Chat Application
										</td>
										<td>
											<Datetime time="9:20AM" />
										</td>
									</tr>
									<tr>
										<td>
											<img className="me-2" src={folderIcon} alt="folder_icon" />
											&nbsp;Chat Application
										</td>
										<td>
											<Datetime time="9:20AM" />
										</td>
									</tr>
									<tr>
										<td>
											<img className="me-2" src={textIcon} alt="text_icon" />
											&nbsp;Chat Application
										</td>
										<td>
											<Datetime time="9:20AM" />
										</td>
									</tr>
									<tr>
										<td>
											<img className="me-2" src={rarIcon} alt="rar_icon" />
											&nbsp;Chat Application
										</td>
										<td>
											<Datetime time="9:20AM" />
										</td>
									</tr>
								</tbody>
							</table> */}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default GuardRoute(DownloadHistory);
