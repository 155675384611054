/* START: React */
import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import registerServiceWorker from "./registerServiceWorker";
/* END: React */

/* START: Router */
import { BrowserRouter } from "react-router-dom";
/* End: Router */ 

/* START: Redux */
import { createStore, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";
import { Provider } from "react-redux";
import rootReducer from "./store/reducers/rootReducer";
import { LOGIN_USER_SUCCESS } from "./store/actions/types";
/* End: Redux */

/* START: CSS */
import "assets/scss/style.css";
import "assets/scss/Mobile.css"; 
import "assets/scss/react.scss";
/* END: CSS */

// Setting up redux store
const composeEnhancers =
  (process.env.NODE_ENV === "development"
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    : null) || compose;
const store = createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(thunk))
);

//Reloading store state
const loggedInUser = JSON.parse(localStorage.getItem("authData"));

if (loggedInUser?.user) {
  const update = {
    user: { ...loggedInUser?.user },
    authData: { ...loggedInUser.authData },
    isAuth: true,
  };
  store.dispatch({ type: LOGIN_USER_SUCCESS, payload: update });
}

ReactDOM.render(
  <BrowserRouter>
    <Provider store={store}>
      <App />
    </Provider>
  </BrowserRouter>,
  document.getElementById("root")
);
registerServiceWorker();
