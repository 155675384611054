import routes from "./Routes";
import { useLocation, matchRoutes } from "react-router-dom";

class Router {
  static getRoute(routeKey, params) {
    if (!(routeKey in routes)) {
      return "/not-found";
    }
    let route = routes[routeKey].route;
    for (const param in params) {
      const regExp = new RegExp(`:${param}`, "g");
      route = route.replace(regExp, params[param]);
    }
    return route;
  }

  static getText(routeKey) {
    return routes[routeKey].text;
  }

  static injectComponent(routeKey, props) {
    const Component = routes[routeKey].component;
    return <Component {...props} />;
  }

  static isRouteActive(routeKey) {
    return this.getRoute(routeKey) === this.getCurrentRawRoute;
  }

  static getMetaByRoute(route) {
    let meta = {};
    for (const routeKey in routes) {
      if (routes[routeKey].route === route) {
        meta = routes[routeKey].meta;
      }
    }
    return meta;
  }

  static get getCurrentMeta() {
    const rawUrl = this.getCurrentRawRoute;
    return Router.getMetaByRoute(rawUrl);
  }

  static get getCurrentRawRoute() {
    const arr = [];
    for (const routeKey in routes) {
      arr.push({ path: routes[routeKey]?.route });
    }

    const route = matchRoutes(arr, useLocation());
    if (!route) {
      return "/not-found";
    }
    return route[0]?.route?.path;

    /**@eliw00d I had the same issue with nested routes. I solved this by searching the matching routes by the pathname: matchRoutes(routes, location).find(m => m.pathname === location.pathname). This should leave you with the exact route match only, and not the parent routes. */
  }
}

export default Router;
