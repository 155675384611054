import React, { useEffect, useState } from "react";
import { GuardRoute } from "hoc";
import { Sidemenu, Hero } from "layouts";
import { Datetime, BannerVideo, TableCaption, Table } from "components";
import {
  pdfIcon,
  zipIcon,
  folderIcon,
  textIcon,
  rarIcon,
  copyIcon,
} from "components/Images";
import { useDispatch } from "react-redux";
import { getDownloadedDocs } from "actions/common";

const tableHeaderData = ["File Name", "Date/Time"];

const DownloadedDocs = () => {
  const [downloadedDocs, setDownloadedDocs] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      getDownloadedDocs((err, response) => {
        console.log(response);
        response?.downloadedDocs &&
          response?.downloadedDocs.length > 0 &&
          setDownloadedDocs([...response?.downloadedDocs]);
      })
    );
  }, []);
  return (
    <>
      <Hero>Downloaded Documents</Hero>
      <section className="bannerSection py-13">
        <div className="container">
          <BannerVideo />
          <div className="row gx-9">
            <div className="col-auto">
              <Sidemenu />
            </div>
            <div className="col">
              <TableCaption>List of Downloaded documents</TableCaption>
              <div className="dashboard-table-frame">
              {downloadedDocs && downloadedDocs.length > 0 && (
                <Table
                  tableHeaderData={tableHeaderData}
                  tableData={downloadedDocs}
                />
              )}
              {/* <table className="table table-borderless mb-0">
                <thead className="bg-primary text-white border-0">
                  <tr>
                    <th>File Name</th>
                    <th>Date/Time</th>
                  </tr>
                </thead>
                <tbody className="small align-middle">
                  <tr>
                    <td>
                      <img className="me-2" src={pdfIcon} alt="pdf_icon" />
                      &nbsp;Chat Application
                    </td>
                    <td>
                      <Datetime date="18 March 2023" time="9:20AM" />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <img className="me-2" src={zipIcon} alt="zip_icon" />
                      &nbsp;Chat Application
                    </td>
                    <td>
                      <Datetime date="18 March 2023" time="9:20AM" />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <img
                        className="me-2"
                        src={folderIcon}
                        alt="folder_icon"
                      />
                      &nbsp;Chat Application
                    </td>
                    <td>
                      <Datetime date="18 March 2023" time="9:20AM" />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <img className="me-2" src={textIcon} alt="text_icon" />
                      &nbsp;Chat Application
                    </td>
                    <td>
                      <Datetime date="18 March 2023" time="9:20AM" />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <img className="me-2" src={rarIcon} alt="rar_icon" />
                      &nbsp;Chat Application
                    </td>
                    <td>
                      <Datetime date="18 March 2023" time="9:20AM" />
                    </td>
                  </tr>
                </tbody>
              </table> */}
            </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default GuardRoute(DownloadedDocs);
