import React from "react";
import { NavLink, Link, useNavigate } from "react-router-dom";
import { Router } from "router";
import { logo } from "components/Images";
import { highlightIfActive } from "classes";
import { useSelector, useDispatch } from "react-redux";
import { logoutUser } from "store/actions";
import { slide as Menu } from 'react-burger-menu'

const Navbar = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleLogout = (e) => {
    e.preventDefault();
    dispatch(logoutUser(navigate));
  };

  const { isAuth } = useSelector(({ auth }) => auth);
  let menuLinks;
  if (isAuth) {
    menuLinks = (
      <li className="nav-item profile-main-header">
        <div className="profile-setion-main">
        <a className="btn btn-primary">
            <ion-icon className="me-1" name="person" />
            </a>
          </div>
          <div className="profile-hover-section">
             <a href="/dashboard">Dashboard</a>
             <a href="/edit-profile">Profile</a>
             <a href="/uploaded-docs">Uploaded docs</a>
             <a className="log-out-menu"  onClick={handleLogout}> Logout </a>  
         </div>     
      </li>
    );
  } else {
    menuLinks = (
      <li className="nav-item">
        <a href={Router.getRoute("login")} className="btn btn-primary">
          <ion-icon className="me-1" name="person" />
          &nbsp; {Router.getText("login")}
        </a>
      </li>
    );
  }

  return (
    <header>
      <nav className="navbar navbar-expand-lg" style={{ background: "white" }}>
        <div className="container-fluid">
          <NavLink to={"/"} className="navbar-brand py-0" end>
            <img src={logo} alt="Future Safe" height={40} />
          </NavLink>
          <div className="mobilemenu-new">
              <Menu>
            
            <ul>
              <li className={`nav-item ${highlightIfActive("home")}`}>
                <a href={Router.getRoute("home")} className="nav-link" end>
                  {Router.getText("home")}
                </a>
              </li>
              <li className={`nav-item ${highlightIfActive("whatWeDo")}`}>
                <a
                  href={Router.getRoute("whatWeDo")}
                  className="nav-link"
                  end
                > 
                  {Router.getText("whatWeDo")}
                </a>
              </li>
              <li className={`nav-item ${highlightIfActive("solutions")}`}>
                <a
                  href={Router.getRoute("solutions")}
                  className="nav-link"
                  end
                >
                  {Router.getText("solutions")}
                </a>
              </li>
              <li className={`nav-item ${highlightIfActive("resources")}`}>
                <a
                  href={Router.getRoute("resources")}
                  className="nav-link"
                  end
                >
                  {Router.getText("resources")}
                </a>
              </li>
              <li className={`nav-item ${highlightIfActive("partners")}`}>
                <a
                  href={Router.getRoute("partners")}
                  className="nav-link"
                  end
                >
                  {Router.getText("partners")}
                </a>
              </li>
              <li className={`nav-item ${highlightIfActive("company")}`}>
                <a
                  href={Router.getRoute("company")}
                  className="nav-link"
                  end
                >
                  {Router.getText("company")}
                </a>
              </li>
              {menuLinks}
            </ul>
          
          </Menu>
      </div>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav ms-auto mb-2 mb-lg-0 text-uppercase align-items-center">
              <li className={`nav-item ${highlightIfActive("home")}`}>
                <NavLink to={Router.getRoute("home")} className="nav-link" end>
                  {Router.getText("home")}
                </NavLink>
              </li>
              <li className={`nav-item ${highlightIfActive("whatWeDo")}`}>
                <NavLink
                  to={Router.getRoute("whatWeDo")}
                  className="nav-link"
                  end
                >
                  {Router.getText("whatWeDo")}
                </NavLink>
              </li>
              <li className={`nav-item ${highlightIfActive("solutions")}`}>
                <NavLink
                  to={Router.getRoute("solutions")}
                  className="nav-link"
                  end
                >
                  {Router.getText("solutions")}
                </NavLink>
              </li>
              <li className={`nav-item ${highlightIfActive("resources")}`}>
                <NavLink
                  to={Router.getRoute("resources")}
                  className="nav-link"
                  end
                >
                  {Router.getText("resources")}
                </NavLink>
              </li>
              <li className={`nav-item ${highlightIfActive("partners")}`}>
                <NavLink
                  to={Router.getRoute("partners")}
                  className="nav-link"
                  end
                >
                  {Router.getText("partners")}
                </NavLink>
              </li>
              <li className={`nav-item ${highlightIfActive("company")}`}>
                <NavLink
                  to={Router.getRoute("company")}
                  className="nav-link"
                  end
                >
                  {Router.getText("company")}
                </NavLink>
              </li>
              {menuLinks}
            </ul>
          </div>
        </div>
      </nav>
    </header>
  );
};

export default Navbar;
