import React from 'react';
import { square } from 'components/Images';

const Hero = (props) => {
	let content;
	if ('contentHtml' in props) {
		content = props.contentHtml;
	} else {
		content = (
			<div className="col text-center">
				<h1 className="display-4 mb-0 fw-semibold text-white">{props.children}</h1>
			</div>
		);
	}

	return (
		<section className="pageHeader bg-indigo py-7">
			<img src={square} />
			<img src={square} />
			<div className="container edit-profile-main-page">
				<div className="row align-items-center gx-5">{content}</div>
			</div>
		</section>
	);
};

export default Hero;
