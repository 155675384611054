import React from 'react';
import { timeIcon, calIcon } from 'components/Images';

const Datetime = ({ date, time }) => {
	return (
		<>
			<img className={`icon me-1 ${date ? 'ms-2' : ''}`} src={calIcon} alt="time_icon" width="20" />
			{date ? `${date}\u00A0` : ''}
			<img className={`icon me-1 ${date ? 'ms-2' : ''}`} src={timeIcon} alt="time_icon" />
			{time}
		</>
	);
};

export default Datetime;
