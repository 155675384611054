import { LOGIN_USER_SUCCESS, LOGOUT_USER_SUCCESS, UPDATE_USER_SUCCESS } from 'store/actions/types';

const initState = {
	isAuth: false,
	user: null,
	authData:null
};

const authReducer = (state = initState, action) => {
	switch (action.type) {
		case LOGIN_USER_SUCCESS:
			return {
				...state,
				...action.payload,
				isAuth: true,
			};
		case LOGOUT_USER_SUCCESS:
			return {
				...state,
				isAuth: false,
			};
		case UPDATE_USER_SUCCESS:
			return {
				...state,
				...action.payload,
			};
	}
	return state;
};

export default authReducer;
