import React, { Component } from "react";
import { GuardRoute } from "hoc";
import { Sidemenu, Hero } from "layouts";
import { BannerVideo, DynamicHtml } from "components";
import profile from "assets/img/profile.webp";
import { compose } from "redux";
import { connect } from "react-redux";
import { Validator } from "classes";
import { updateProfile, updateProfileImage } from "store/actions";
import { editIcon, loadingGif } from "components/Images";

class EditProfile extends Component {
  state = {
    form: new Validator(
      {
        first_name: {
          value: this.props.user.first_name,
          rules: ["required"],
        },
        last_name: {
          value: this.props.user.last_name,
          rules: ["required"],
        },
        email: {
          value: this.props.user.email,
          rules: ["required", "email"],
        },
        phone: {
          value: this.props.user.phone,
          rules: ["required"],
        },
        newPassword: {
          value: "",
          rules: [],
          name: "New Password",
        },
        confirmPassword: {
          value: "",
          rules: ["requiredIf:newPassword,="],
          name: "Confirm Password",
        },
      },
      this,
      "form"
    ),
    isUpdateClicked: false,
    isUpdateImageClicked: false,
  };
  submitUpdateForm = (e) => {
    e.preventDefault();
    this.setState({ ...this.state, ...{ isUpdateClicked: true } });
    this.state.form
      .validate()
      .then(() => {
        const {
          first_name,
          last_name,
          email,
          phone,
          newPassword,
          confirmPassword,
        } = this.state.form.fields;

        this.props.updateProfile(
          {
            first_name,
            last_name,
            email,
            phone,
            password: newPassword,
            confirm_password: confirmPassword,
          },
          () => {
            this.setState({ ...this.state, ...{ isUpdateClicked: false } });
          }
        );
      })
      .catch((error) => {
        console.log(error);
        this.setState({ ...this.state, ...{ isUpdateClicked: false } });
      });
  };

  updateProfileImage = (e) => {
    console.log(e.target.files);
    this.setState({ ...this.state, ...{ isUpdateImageClicked: true } });
    this.props.updateProfileImage(e.target.files[0], () => {
      this.setState({ ...this.state, ...{ isUpdateImageClicked: false } });
    });
  };

  render() {
    const heroContent = (
      <>
        <div className="col-auto">
          <div
            className="rounded-circle border border-4 border-white"
            style={{ position: "relative" }}
          >
            {this.state.isUpdateImageClicked ? (
              <img
                src={loadingGif}
                alt="img"
                className="headerImg roundded-circle img-fluid ratio ratio-1x1"
                style={{ borderRadius: "100%" }}
              />
            ) : (
              <img
                src={this.props.user.avatar ?? profile}
                alt="img"
                className="headerImg roundded-circle img-fluid ratio ratio-1x1"
                style={{ borderRadius: "100%" }}
              />
            )}
            <div
              style={{
                position: "absolute",
                bottom: "0px",
                right: "0px",
                backgroundColor: "lightgrey",
                borderRadius: "100%",
                padding: "0px",
              }}
            >
              <label className="profile-pic-update-cu" htmlFor="profile-pic-update">
                <img
                  src={editIcon}
                  style={{ height: "2.5em", width: "2.5em" }}
                />
              </label>
              <input
                type="file"
                id="profile-pic-update"
                style={{ display: "none" }}
                onChange={this.updateProfileImage}
                disabled={this.state.isUpdateImageClicked}
              />
            </div>
          </div>
        </div>
        <div className="col-auto profileInfo">
          <h4 className="mb-1 text-white">
            {this.props.user.first_name} {this.props.user.last_name}
          </h4>
          <p className="mb-0 none">Creative Director</p>
        </div>
        <div className="col text-center">
          <h1 className="display-4 mb-0 fw-semibold text-white">Profile</h1>
        </div>
      </>
    );

    return (
      <>
        <Hero contentHtml={heroContent}></Hero>
        <section className="bannerSection py-13">
          <div className="container">
            <BannerVideo />
            <div className="row gx-9">
              <div className="col-auto">
                <Sidemenu />
              </div>
              <div className="col">
                <div className="mb-10">
                  <h3 className="title-header text-white fw-medium mb-5">
                    Edit Basic Information
                  </h3>
                  <div className="row gx-10 gy-6">
                    <div className="col-6">
                      <label htmlFor="first_name" className="form-label">
                        First Name
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        value={this.state.form.fields.first_name}
                        onChange={this.state.form.handleChange}
                        name="first_name"
                        id="first_name"
                      />
                      <DynamicHtml>
                        {this.state.form.renderError("first_name")}
                      </DynamicHtml>
                    </div>
                    <div className="col-6">
                      <label htmlFor="last_name" className="form-label">
                        Last Name
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        value={this.state.form.fields.last_name}
                        onChange={this.state.form.handleChange}
                        name="last_name"
                        id="last_name"
                      />
                      <DynamicHtml>
                        {this.state.form.renderError("last_name")}
                      </DynamicHtml>
                    </div>
                    <div className="col-6">
                      <label htmlFor="email" className="form-label">
                        Email
                      </label>
                      <input
                        type="email"
                        className="form-control"
                        value={this.state.form.fields.email}
                        onChange={this.state.form.handleChange}
                        name="email"
                        id="email"
                      />
                      <DynamicHtml>
                        {this.state.form.renderError("email")}
                      </DynamicHtml>
                    </div>
                    <div className="col-6">
                      <label htmlFor="phone" className="form-label">
                        Phone
                      </label>
                      <input
                        type="tel"
                        className="form-control"
                        value={this.state.form.fields.phone}
                        onChange={this.state.form.handleChange}
                        name="phone"
                        id="phone"
                      />
                      <DynamicHtml>
                        {this.state.form.renderError("phone")}
                      </DynamicHtml>
                    </div>
                  </div>
                </div>
                <div className="mb-0">
                  <h3 className="title-header text-white fw-medium mb-5">
                    Change Password
                  </h3>
                  <div className="row gx-10 gy-6">
                    {/* <div className="col-6">
												<label htmlFor="oldPassword" className="form-label">
													Old Password
												</label>
												<input type="password" className="form-control" id="oldPassword" />
											</div> */}
                    <div className="col-6">
                      <label htmlFor="newPassword" className="form-label">
                        New Password
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        value={this.state.form.fields.newPassword}
                        onChange={this.state.form.handleChange}
                        name="newPassword"
                        id="newPassword"
                      />
                      <DynamicHtml>
                        {this.state.form.renderError("newPassword")}
                      </DynamicHtml>
                    </div>
                    <div className="col-6">
                      <label htmlFor="confirmPassword" className="form-label">
                        Confirm Password
                      </label>
                      <input
                        type="password"
                        className="form-control"
                        value={this.state.form.fields.confirmPassword}
                        onChange={this.state.form.handleChange}
                        name="confirmPassword"
                        id="confirmPassword"
                      />
                      <DynamicHtml>
                        {this.state.form.renderError("confirmPassword")}
                      </DynamicHtml>
                    </div>
                    <div className="col-12">
                      <button
                        className="btn btn-primary"
                        onClick={this.submitUpdateForm}
                        disabled={this.state.isUpdateClicked}
                      >
                        Update
                        <ion-icon role="img" name="arrow-forward-outline" />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  }
}

const mapStateToProps = ({ auth }) => {
  const { user } = auth;
  return {
    user,
    auth,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateProfile: (data, callback) => dispatch(updateProfile(data, callback)),
    updateProfileImage: (file, callback) =>
      dispatch(updateProfileImage(file, callback)),
  };
};

export default compose(
  GuardRoute,
  connect(mapStateToProps, mapDispatchToProps)
)(EditProfile);
