import React, { useEffect, useState } from "react";
import { GuardRoute } from "hoc";
import { Sidemenu, Hero } from "layouts";
import { Datetime, BannerVideo, TableCaption, Table } from "components";
import { useDispatch } from "react-redux";
import { getUploadedDocs } from "actions/common";

const tableHeaderData = ["File Name", "Upload Time","Action"];

const UploadedDocs = () => {
  const [uploadedDocs, setUploadedDocs] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      getUploadedDocs((err, response) => {
        console.log(response);
        response?.documents &&
          response?.documents.length > 0 &&
          setUploadedDocs([...response?.documents]);
      })
    );
  }, []);

  return (
    <>
      <Hero>Uploaded Documents</Hero>
      <section className="bannerSection py-13">
        <div className="container">
          <BannerVideo />
          <div className="row gx-9">
            <div className="col-auto">
              <Sidemenu />
            </div>
            <div className="col">
              <TableCaption>List of Uploaded documents</TableCaption>
              <div className="dashboard-table-frame">
              {uploadedDocs && uploadedDocs.length > 0 && (
                <Table 
                  tableHeaderData={tableHeaderData}
                  tableData={uploadedDocs}
                  copyEnabled={true}
                  downloadEnabled={true}
                />
              )}
            </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default GuardRoute(UploadedDocs);
