import axios from "axios";

const getRequest = (url, params = {}, token = null, withCredentials = false) => {
    let header = {};
    if(token){
        header = { 'Authorization' : `Bearer ${token}`}
    }

    return axios({
        method:'GET',
        url,
        withCredentials:false,
        params: {
            ...params
        },
        headers: {...header}
    })
}

const postRequest = (url, data={}, token = null, withCredentials = false) => {
    let header = {};
    if(token){
        header = { 
            'Authorization' : `Bearer ${token}`
        }
    }

    return axios({
        method:'POST',
        url,
        withCredentials:false,
        data,
        headers: {
            ...header,
            'Content-Type': 'application/json',
        }
    })
}

const postRequestWithoutAuth = (url, data = {}) => {
    let header = {};
  
    return axios({
      method: "POST",
      url,
      withCredentials: false,
      data,
      headers: {
        ...header,
        "Content-Type": "application/json",
      },
    });
  };

const uploadFile = (url, data, token, withCredentials) => {
    const headers = {
        'Authorization' : `Bearer ${token}`,
        'Content-Type' : 'multipart/form-data'
    };
    const formData = new FormData();
    formData.append('image',data);

    return axios({
        method:'POST',
        url,
        withCredentials:false,
        data: formData,
        headers: {...headers }
    })
}

export {
    getRequest,
    postRequest,
    uploadFile,
    postRequestWithoutAuth
}