import { getPageContent, sendMessage, updatePassword } from "actions/common";
import { toast } from "react-toastify";
import { GuardRoute } from "hoc";
import React, { useEffect, useState, Component, useReducer } from "react";
import { Helmet } from "react-helmet";
import { useDispatch, connect } from "react-redux";
import { compose } from "redux";
import { useLocation, useNavigate } from "react-router-dom";

import { Hero } from "layouts";
import { BannerVideo } from "components";
import { Validator } from "classes";
import { sendContactEnquiry } from "store/actions";
// import Captcha from "components/GoogleRecaptch";
import { googleRecaptchSiteKey } from "config/dev.config";

const formReducer = (state, event) => {
  return {
    ...state,
    [event.name]: event.value,
  };
};

const UpdatePassword = (props) => {
  const [formData, setFormData] = useReducer(formReducer, {});
  const [submitting, setSubmitting] = useState(false);
  const navigate = useNavigate();

  const handleSubmit = (event) => {
    event.preventDefault();
    setSubmitting(true);
    const captchaResponse = window.grecaptcha.getResponse();
    const passwordToken = window.location.pathname;
    const getToken = passwordToken.split("/")[2];
    formData["token"] = getToken;

    if (formData.password != formData.confirm_password) {
      toast.error("password and confirm password does not match", {
        position: "top-right",
      });

      setSubmitting(false);
      return false;
    }
    if (
      captchaResponse == "" ||
      captchaResponse == undefined ||
      captchaResponse.length == 0
    ) {
      toast.error("Please verify captcha", { position: "top-right" });
      setSubmitting(false);
      return false;
    }
    //console.log(formData);
    dispatch(
      updatePassword(formData, (err, response) => {
        console.log(response);
        setSubmitting(false);
        if (!response.error) {
          navigate("/login");
          console.log(response);
        }
      })
    );
  };

  const handleChange = (event) => {
    setFormData({
      name: event.target.name,
      value: event.target.value,
    });
  };

  const { hash, pathname, search } = useLocation();
  const dispatch = useDispatch();
  const [contentLoaded, setContentLoaded] = useState(false);
  const [pageContent, setPageContent] = useState(null);

  function onloadCallback() {
    window.grecaptcha.render(document.getElementById("googleCaptcha"), {
      sitekey: googleRecaptchSiteKey,
      callback: captchaCallBack,
    });
  }

  const captchaCallBack = (token) => {
    console.log("token", token);
  };

  useEffect(() => {
    dispatch(
      getPageContent(pathname.split("/")[1], (err, response) => {
        if (response) {
          console.log(response);
          setPageContent({ ...response.content });
          setContentLoaded(true);
        }
      })
    );
  }, [pathname]);

  useEffect(() => {
    if (contentLoaded) {
      let scriptEle = document.createElement("script");

      scriptEle.setAttribute(
        "src",
        `https://www.google.com/recaptcha/api.js?onload=onloadCallback&render=explicit`
      );
      scriptEle.setAttribute("type", "text/javascript");
      scriptEle.setAttribute("async", true);

      document.body.appendChild(scriptEle);

      // success event
      scriptEle.addEventListener("load", () => {
        window.onloadCallback = onloadCallback;
        console.log("File loaded");
      });
      // error event
      scriptEle.addEventListener("error", (ev) => {
        console.log("Error on loading file", ev);
      });
    }
  }, [contentLoaded]);

  if (contentLoaded) {
    return (
      <>
        <section>
          <Helmet>
            <meta charSet="utf-8" />
            {pageContent?.meta_title && (
              <title>{pageContent?.meta_title}</title>
            )}
          </Helmet>
        </section>

        <Hero>Reset Your Password</Hero>
        <section className="bannerSection py-13">
          <div className="container">
            <BannerVideo />

            {/*submitting && <div>Submtting Form...</div>*/}
            <form onSubmit={handleSubmit}>
              <div className="contact-form">
                <div className="container">
                  <div className="row gx-10 gy-6">
                    <div className="col-12">
                      <label htmlFor="password" className="form-label">
                        Password (*)
                      </label>
                      <input
                        type="password"
                        className="form-control"
                        name="password"
                        id="password"
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  <div className="row gx-10 gy-6 mt-1">
                    <div className="col-12">
                      <label htmlFor="confirm_password" className="form-label">
                        Confirm Password (*)
                      </label>
                      <input
                        type="password"
                        className="form-control"
                        name="confirm_password"
                        id="email"
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  <div className="col-12 mt-5">
                    {/* <Captcha setToken={setToken} /> */}
                    <div
                      id="googleCaptcha"
                      class="g-recaptcha"
                      // data-sitekey={googleRecaptchSiteKey}
                      // data-callback="onSubmit"
                      // data-size="invisible"
                    ></div>
                  </div>

                  <div className="col-12 mt-5">
                    <button
                      className="btn btn-primary"
                      type="submit"
                      disabled={submitting}
                      // onClick={handleSubmit}
                    >
                      Update Password
                      <ion-icon role="img" name="arrow-forward-outline" />
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </section>
      </>
    );
  } else {
    return null;
  }
};
export default GuardRoute(UpdatePassword);
